import { IUsersSlice } from '@app/api/table.api';
import { createSlice } from '@reduxjs/toolkit';
import {
  getUsers,
  getMerchants,
  getMerchantUsers,
  getInvitationsList,
  getUserById,
  getUserBalanceHistory,
  getRequestedUser,
} from '../actions/users';
import { USER_DATA } from '@app/constants/profileNavData';

const initialState: IUsersSlice = {
  users: [],
  loading: false,
  total: 1,
  merchants: [],
  merchantsTotal: 1,
  merchantsLoading: false,
  invitations: [],
  invitationLoading: false,
  invitationTotal: 1,
  userDetails: USER_DATA,
  balanceHistory: [],
  userDetailsLoading: false,
  balanceHistoryLoading: false,
  balanceHistoryTotal: 1,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setEmptyUserData: (state) => {
      state.userDetails = USER_DATA;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUsers.fulfilled, (state, { payload }) => {
        state.users = payload?.data?.data;
        state.total = payload?.data?.total;
        state.loading = false;
      })
      .addCase(getUsers.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(getMerchants.pending, (state) => {
        state.merchantsLoading = true;
      })
      .addCase(getMerchants.fulfilled, (state, { payload }) => {
        state.merchants = payload?.data?.data;
        state.merchantsTotal = payload?.data?.total;
        state.merchantsLoading = false;
      })
      .addCase(getMerchants.rejected, (state) => {
        state.merchantsLoading = false;
      });
    builder
      .addCase(getMerchantUsers.pending, (state) => {
        state.merchantsLoading = true;
      })
      .addCase(getMerchantUsers.fulfilled, (state, { payload }) => {
        state.merchants = payload?.data?.data;
        state.merchantsTotal = payload?.data?.total;
        state.merchantsLoading = false;
      })
      .addCase(getMerchantUsers.rejected, (state) => {
        state.merchantsLoading = false;
      });
    builder
      .addCase(getInvitationsList.pending, (state) => {
        state.invitationLoading = true;
      })
      .addCase(getInvitationsList.fulfilled, (state, { payload }) => {
        state.invitations = payload?.data?.data;
        state.invitationTotal = payload?.data?.total;
        state.invitationLoading = false;
      })
      .addCase(getInvitationsList.rejected, (state) => {
        state.invitationLoading = false;
      });
    builder
      .addCase(getUserById.pending, (state) => {
        state.userDetailsLoading = true;
      })
      .addCase(getUserById.fulfilled, (state, { payload }) => {
        state.userDetails = payload?.data;
        state.userDetailsLoading = false;
      })
      .addCase(getUserById.rejected, (state) => {
        state.userDetailsLoading = false;
      });
    builder
      .addCase(getRequestedUser.pending, (state) => {
        state.userDetailsLoading = true;
      })
      .addCase(getRequestedUser.fulfilled, (state, { payload }) => {
        state.userDetails = payload?.merchantRequest;
        state.userDetailsLoading = false;
      })
      .addCase(getRequestedUser.rejected, (state) => {
        state.userDetailsLoading = false;
      });
    builder
      .addCase(getUserBalanceHistory.pending, (state) => {
        state.balanceHistoryLoading = true;
      })
      .addCase(getUserBalanceHistory.fulfilled, (state, { payload }) => {
        state.balanceHistory = payload?.data;
        state.balanceHistoryLoading = false;
      })
      .addCase(getUserBalanceHistory.rejected, (state) => {
        state.balanceHistoryLoading = false;
      });
  },
});

export const { setEmptyUserData } = usersSlice.actions;

export default usersSlice.reducer;
