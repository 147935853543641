import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import './editor.css';

interface IEditor {
  data: string;
  setEditorValue: Dispatch<SetStateAction<string>>;
}

export const EditorComponent: FC<IEditor> = ({ data, setEditorValue }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
    setEditorValue(draftToHtml(convertToRaw(newEditorState.getCurrentContent())));
  };

  useEffect(() => {
    setEditorState(() => {
      if (data) {
        const blocksFromHTML = convertFromHTML(data);
        const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
        return EditorState.createWithContent(contentState);
      }
      return EditorState.createEmpty();
    });

    setEditorValue(data);
  }, [data]);

  return (
    <div className="field_Container width_95">
      <Editor
        toolbar={{
          options: ['inline', 'blockType', 'list', 'link'],
          inline: {
            inDropdown: false,
            options: ['bold', 'italic', 'underline', 'strikethrough'],
          },
          list: { inDropdown: false, options: ['unordered', 'ordered'] },
          link: { inDropdown: false, options: ['link'] },
        }}
        editorState={editorState}
        wrapperClassName="editor_wrapper"
        editorClassName="editor_container"
        onEditorStateChange={onEditorStateChange}
      />
    </div>
  );
};
