import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { TradesHistoryTable } from '@app/components/tables/Tables/TradesHistory';
import { useTranslation } from 'react-i18next';

const TradesHistory = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('common.tradesHistory')}</PageTitle>
      <TradesHistoryTable />
    </>
  );
};

export default TradesHistory;
