import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { TradesDetailsTable } from '@app/components/tables/Tables/TradeDetailsTable';
import { useTranslation } from 'react-i18next';

const TradeDetails = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageTitle>{t('common.tradeRequests')}</PageTitle>
      <TradesDetailsTable />
    </>
  );
};

export default TradeDetails;
