import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useNavigate } from 'react-router-dom';
import { SocialIconsTable } from '@app/components/tables/Tables/SocialIconsTable';
import { setSocialLinkEditData } from '@app/store/slices/contentSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { SOCIAL_ICONS_DATA } from '@app/constants/content';

const SocialIcons: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleCreate = () => {
    dispatch(setSocialLinkEditData(SOCIAL_ICONS_DATA));
    navigate('create');
  };

  return (
    <>
      <PageTitle>{t('common.merchants')}</PageTitle>
      <BaseCol flex={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <BaseButton type="primary" onClick={handleCreate}>
          {t('buttons.create')}
        </BaseButton>
      </BaseCol>

      <SocialIconsTable />
    </>
  );
};

export default SocialIcons;
