import { createSlice } from '@reduxjs/toolkit';

import { getTrades, getTradeRequests, getChatMessages } from '../actions/trades';
import { IChatMessages, ITradesSlice } from '@app/api/trades.api';

const initialState: ITradesSlice = {
  data: [],
  tradeLoading: false,
  tradeTotal: 0,
  tradeRequests: [],
  requestLoading: false,
  requestTotal: 0,
  priceRate: '',
  tradeRequest: {
    amount: '',
    created_at: '',
    currency: '',
    currency_id: 0,
    id: 0,
    merchant_status: '',
    priceRate: '',
    requester: '',
    requester_id: '',
    requester_rating: '',
    roomId: null,
    roomTitle: '',
    type: '',
    trade_id: '',
    updated_at: '',
    user_status: '',
    nickname: '',
    is_arbitraged: false,
  },
  messages: [],
};

const tradesSlice = createSlice({
  name: 'trades',
  initialState,
  reducers: {
    setTradeRequest: (state, { payload }) => {
      state.tradeRequest = payload;
    },
    setChatMessages: (state, { payload }) => {
      state.messages = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTrades.pending, (state) => {
        state.tradeLoading = true;
      })
      .addCase(getTrades.fulfilled, (state, { payload }) => {
        state.data = payload?.data;
        state.tradeTotal = payload?.total;
        state.tradeLoading = false;
      })
      .addCase(getTrades.rejected, (state) => {
        state.tradeLoading = false;
      });
    builder
      .addCase(getTradeRequests.pending, (state) => {
        state.requestLoading = true;
      })
      .addCase(getTradeRequests.fulfilled, (state, { payload }) => {
        state.tradeRequests = payload?.data;
        state.requestTotal = payload?.total;
        state.priceRate = payload?.priceRate;
        state.requestLoading = false;
      })
      .addCase(getTradeRequests.rejected, (state) => {
        state.requestLoading = false;
      });
    builder.addCase(getChatMessages.fulfilled, (state, { payload }) => {
      state.messages = payload?.data?.sort((a: IChatMessages, b: IChatMessages) => {
        return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
      });
    });
  },
});

export const { setTradeRequest, setChatMessages } = tradesSlice.actions;

export default tradesSlice.reducer;
