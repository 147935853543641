import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';

import { ChatMessages } from './ChatMessages';

import sendIcon from './images/send.svg';
import uploadIcon from './images/upload.svg';
import { ImagesGrid } from './UploadImage';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { socket } from '@app/socket';
import { getChatMessages } from '@app/store/actions/trades';
import { SHOW_SEE_CHAT_BUTTON } from '@app/constants/tables';
import { IChatMessages } from '@app/api/trades.api';

export const Chat = () => {
  const [chatValue, setChatValue] = useState<string>('');
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const user = useAppSelector((state) => state.user.user);
  const tradeRequest = useAppSelector((state) => state.trades.tradeRequest);
  const messagesList = useAppSelector((state) => state.trades.messages);

  const [messages, setMessages] = useState<IChatMessages[]>(messagesList);

  const dispatch = useAppDispatch();

  const textArea = document.getElementById('chat_input_id');

  const handleUploadImage = (event: ChangeEvent<HTMLInputElement>) => {
    setUploadedFiles((prev) => [...prev, event.target.files?.[0] as File]);
  };

  const handleSend = async (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    const readFileAsArrayBuffer = (file: File) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (event) {
          resolve({ fileName: file.name, fileData: event.target?.result });
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      });
    };
    Promise.all(uploadedFiles.map(readFileAsArrayBuffer))
      .then((images) => {
        const data = {
          room_id: Number(tradeRequest?.roomId),
          roomTitle: tradeRequest?.roomTitle,
          sender_id: user?.id,
          message: chatValue,
          created_at: new Date(),
          attachments: images,
          is_admin: true,
          sender_name: `${user?.firstName}`,
          is_arbitraged: false,
          request_state: '',
          admin_action: null,
        };
        const messageData = {
          room_id: Number(tradeRequest?.roomId),
          roomTitle: tradeRequest?.roomTitle,
          sender_id: user?.id,
          message: chatValue,
          created_at: new Date(),
          attachmentPaths: uploadedFiles,
          is_admin: true,
          sender_name: `${user?.firstName}`,
          is_arbitraged: false,
          request_state: '',
          admin_action: null,
        };

        socket.emit('send_message', data);
        setMessages([...messages, messageData]);
      })
      .catch((error) => {
        console.error('Error reading files:', error);
      });

    setChatValue('');
    setUploadedFiles([]);
  };

  useEffect(() => {
    if (textArea) {
      if (textArea.scrollHeight > 76) {
        textArea.style.height = '76px';
      } else {
        textArea.style.height = 'auto';
      }
    }
  }, [chatValue]);

  useEffect(() => {
    socket.emit('joinRoom', tradeRequest?.roomTitle);

    socket.on('receive_message', (data) => {
      setMessages((prev) => [...prev, data]);
    });

    return () => {
      socket.off('receive_message');
    };
  }, [tradeRequest?.roomTitle]);

  useEffect(() => {
    if (tradeRequest?.roomId) dispatch(getChatMessages({ id: Number(tradeRequest?.roomId) }));
  }, [tradeRequest?.roomId]);

  useEffect(() => {
    setMessages(messagesList);
  }, [messagesList]);

  useEffect(() => {
    const chatContainer = document.getElementById('chat_container_id')?.getBoundingClientRect()?.height;
    const chatInformation = document.getElementById('chat_information_id')?.getBoundingClientRect()?.height;
    const chatActionsField = document.getElementById('chat_actions_field_id')?.getBoundingClientRect()?.height;
    const chat_messages_section_id = document.getElementById('chat_messages_section_id');

    if (chat_messages_section_id && chatContainer && chatInformation && chatActionsField)
      chat_messages_section_id.style.maxHeight = chatContainer - (chatInformation + chatActionsField + 4) + 'px';
  }, [uploadedFiles]);

  return (
    <div className="chat_section">
      <ChatMessages messages={messages} />
      {!SHOW_SEE_CHAT_BUTTON.includes(tradeRequest.merchant_status) && (
        <div className="chat_actions_field" id="chat_actions_field_id">
          <div className="chat_input_field">
            <div className="chat_input" onClick={(e) => e.stopPropagation()}>
              <textarea
                className="chat_input"
                id="chat_input_id"
                value={chatValue}
                onChange={(e) => setChatValue(e.target.value)}
                placeholder={'Type Message...'}
              />
              <div className="uploaded_files">
                <input className="upload_file_drag" type="file" id="attach_file" onChange={handleUploadImage} />
                {!!uploadedFiles?.length && <ImagesGrid value={uploadedFiles} setValue={setUploadedFiles} />}
              </div>
            </div>
            <div className="chat_send_upload">
              <button className="chat_send_upload_button">
                <label htmlFor="attach_file">
                  <img src={uploadIcon} className="action_icon" alt="Upload" />
                </label>
              </button>
              <button className="chat_send_upload_button" onClick={handleSend}>
                <img src={sendIcon} className="action_icon" alt="Send" />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
