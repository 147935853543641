import React from 'react';
import { useTranslation } from 'react-i18next';

import { TradeDetailsTreeTable } from '../TreeTable/TradeDetailsTreeTable';

import * as S from './Tables.styles';

export const TradesDetailsTable: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <S.TablesWrapper>
        <S.Card id="tree-table" title={t('common.tradeRequests')} padding="1.25rem 1.25rem 0">
          <TradeDetailsTreeTable />
        </S.Card>
      </S.TablesWrapper>
    </>
  );
};
