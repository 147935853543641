// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard {
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 30px;
  grid-gap: 30px;
  gap: 30px;
}

.dashboard_element {
  border-radius: 12px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.merchants_field {
  border: 2px solid #339cfd;
  background-color: #339cfd69;
}
.users_field {
  border: 2px solid #57d682;
  background-color: #57d68269;
}
.trades_field {
  border: 2px solid #ffb765;
  background-color: #ffb76569;
}

.dashboard_element_title {
  margin: 0;
  padding: 0;
}

@media (max-width: 768px) {
  .dashboard {
    grid-template-columns: repeat(1, 1fr);
    padding: 16px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/DashboardPages/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,sBAAsB;EACtB,aAAa;EACb,qCAAqC;EACrC,aAAa;EACb,cAAS;EAAT,SAAS;AACX;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,yBAAyB;EACzB,2BAA2B;AAC7B;AACA;EACE,yBAAyB;EACzB,2BAA2B;AAC7B;AACA;EACE,yBAAyB;EACzB,2BAA2B;AAC7B;;AAEA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE;IACE,qCAAqC;IACrC,aAAa;EACf;AACF","sourcesContent":[".dashboard {\n  width: 100%;\n  box-sizing: border-box;\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  padding: 30px;\n  gap: 30px;\n}\n\n.dashboard_element {\n  border-radius: 12px;\n  padding: 16px;\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}\n\n.merchants_field {\n  border: 2px solid #339cfd;\n  background-color: #339cfd69;\n}\n.users_field {\n  border: 2px solid #57d682;\n  background-color: #57d68269;\n}\n.trades_field {\n  border: 2px solid #ffb765;\n  background-color: #ffb76569;\n}\n\n.dashboard_element_title {\n  margin: 0;\n  padding: 0;\n}\n\n@media (max-width: 768px) {\n  .dashboard {\n    grid-template-columns: repeat(1, 1fr);\n    padding: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
