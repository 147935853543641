import React, { useState, useEffect } from 'react';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { IBalance, Pagination } from 'api/table.api';
import { useMounted } from '@app/hooks/useMounted';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { blockUser, getUsers } from '@app/store/actions/users';
import { useLocation, useNavigate } from 'react-router-dom';
import { BALANCE_SOURCE, BALANCE_TYPES, TABLES } from '@app/constants/tables';
import { useTranslation } from 'react-i18next';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import moment from 'moment';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { BaseImage } from '@app/components/common/BaseImage/BaseImage';
import { setEmptyUserData } from '@app/store/slices/usersSlice';

export const BalanceHistoryTable: React.FC = () => {
  const [form] = BaseForm.useForm();
  const [isModalActive, setIsModalActive] = useState<boolean>(false);
  const [selectedUserID, setSelectedUserID] = useState<number | null>(null);
  const [userStatus, setUserStatus] = useState<boolean>(false);

  const users = useAppSelector((state) => state.users.users);
  const balanceHistory = useAppSelector((state) => state.users.balanceHistory);
  const loading = useAppSelector((state) => state.users.balanceHistoryLoading);
  const total = useAppSelector((state) => state.users.balanceHistoryTotal);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const query = new URLSearchParams(useLocation().search);
  const selectedPage = query.get(TABLES.page);
  const search = query.get(TABLES.search);

  const [page, setPage] = useState<Pagination>({
    current: selectedPage ? Number(selectedPage) : TABLES.first_page,
    pageSize: TABLES.per_page,
    total: total,
  });

  const { isMounted } = useMounted();
  const dispatch = useAppDispatch();

  const handleTableChange = (pagination: Pagination) => {
    setPage(pagination);
    query.set(TABLES.page, `${pagination?.current}`);
    navigate(`?${query.toString()}`);
  };

  const handleActivateUser = () => {
    const params = {
      filters: { role: TABLES.user_role },
      page: page.current,
      per_page: page.pageSize,
    };
    dispatch(blockUser({ id: selectedUserID as number, params }));
    setIsModalActive(false);
  };

  const handleOpenModal = (status: boolean, id: number) => {
    setIsModalActive(true);
    setSelectedUserID(id);
    setUserStatus(status);
  };

  const handleRowClick = (id: number) => {
    dispatch(setEmptyUserData());
    navigate(`/user-details/${id}`);
  };

  const columns = [
    {
      title: t('common.currency'),
      dataIndex: 'currency',
      key: 'currency',
      render: (text: string, record: IBalance) => {
        return (
          <BaseRow>
            <span className="cut_text_small">{record.currency}</span>
          </BaseRow>
        );
      },
    },
    {
      title: t('common.amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: (text: string, record: IBalance) => {
        return (
          <BaseRow>
            <span className="cut_text_small">{record.amount?.toFixed(2)}</span>
          </BaseRow>
        );
      },
    },
    {
      title: t('common.source'),
      dataIndex: 'type',
      key: 'type',
      render: (text: string, record: IBalance) => {
        const data = BALANCE_SOURCE[record.type];
        return (
          <BaseRow>
            <div className="row_element">
              <BaseImage src={data.icon} alt="Type" preview={false} width={24} height={24} />
              <span className="cut_text_small">{data.title}</span>
            </div>
          </BaseRow>
        );
      },
    },
    {
      title: t('common.type'),
      dataIndex: 'direction',
      key: 'direction',
      render: (text: string, record: IBalance) => {
        const data = BALANCE_TYPES[record.direction];
        return (
          <BaseRow>
            <div className="row_element">
              <BaseImage src={data.icon} alt="Type" preview={false} width={24} height={24} />
              <span className="cut_text_small">{data.title}</span>
            </div>
          </BaseRow>
        );
      },
    },
    {
      title: t('common.date'),
      dataIndex: 'date',
      key: 'date',
      render: (text: string, record: IBalance) => {
        return <BaseRow>{moment(record?.date).format('DD.MM.YYYY h:mm A')}</BaseRow>;
      },
    },
  ];

  useEffect(() => {
    if (selectedPage) setPage((state) => ({ ...state, current: Number(selectedPage) }));
  }, [selectedPage]);

  useEffect(() => {
    if (isMounted) {
      const params = {
        filters: { role: TABLES.user_role },
        page: page.current,
        per_page: page.pageSize,
        search: search ?? '',
      };
      dispatch(getUsers({ params }));
    }
  }, [page, search]);

  return (
    <BaseForm form={form} component={false}>
      <BaseTable
        columns={columns}
        dataSource={balanceHistory}
        pagination={page}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
      />
      <BaseModal
        title={t('modals.userActivation')}
        open={isModalActive}
        onOk={handleActivateUser}
        onCancel={() => setIsModalActive(false)}
      >
        {userStatus ? <p>{t('modals.user_unblock')}</p> : <p>{t('modals.user_block')}</p>}
      </BaseModal>
    </BaseForm>
  );
};
