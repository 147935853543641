export const PATHNAMES = {
  social_links: '/content/social-links',
};

export const SEARCH_HIDE_PATHS = [
  'content/main-section',
  'content/for-merchants',
  'content/about-us',
  'content/privacy-policy',
  'content/terms-of-use',
  'content/contact-information',
  'content/faq',
  'content/social-links',
  'content/social-links/create',
  'content/social-links/edit/:id',
  'change-password',
  '/',
  '/trades/details/:id',
  '/user-details/:id',
  '/content/faq/create',
  '/content/faq/edit/:id',
];

export const MOBILE_WINDOW_WIDTH = 768;

export const IMAGES_COUNT = {
  desktop: 5,
  mobile: 3,
};
