import { configureStore } from '@reduxjs/toolkit';

import userReducer from '@app/store/slices/userSlice';
import authReducer from '@app/store/slices/authSlice';
import nightModeReducer from '@app/store/slices/nightModeSlice';
import themeReducer from '@app/store/slices/themeSlice';
import pwaReducer from '@app/store/slices/pwaSlice';
import usersReducer from '@app/store/slices/usersSlice';
import contentReducer from '@app/store/slices/contentSlice';
import tradesReducer from '@app/store/slices/tradesSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    auth: authReducer,
    nightMode: nightModeReducer,
    theme: themeReducer,
    pwa: pwaReducer,
    users: usersReducer,
    content: contentReducer,
    trades: tradesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
