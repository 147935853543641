import React, { useState, useEffect } from 'react';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { IBody, Pagination } from 'api/table.api';
import { useMounted } from '@app/hooks/useMounted';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { TABLES } from '@app/constants/tables';
import { useTranslation } from 'react-i18next';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseTooltip } from '@app/components/common/BaseTooltip/BaseTooltip';
import { getSocialIcons } from '@app/store/actions';
import { ISocialLinks } from '@app/api/content.api';
import { BaseImage } from '@app/components/common/BaseImage/BaseImage';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { deleteSocialIcon } from '@app/store/actions/content';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { setSocialLinkEditData } from '@app/store/slices/contentSlice';
import { SOCIAL_ICONS_DATA } from '@app/constants/content';

const imageUrl = process.env.REACT_APP_IMAGE_URL;

export const SocialIconsTreeTable: React.FC = () => {
  const [isModalActive, setIsModalActive] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);

  const [form] = BaseForm.useForm();

  const data = useAppSelector((state) => state.content.socialLinks);
  const loading = useAppSelector((state) => state.content.faqLoading);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const query = new URLSearchParams(useLocation().search);
  const selectedPage = query.get(TABLES.page);

  const [page, setPage] = useState<Pagination>({
    current: selectedPage ? Number(selectedPage) : TABLES.first_page,
    pageSize: TABLES.per_page,
    total: 1,
  });

  const { isMounted } = useMounted();
  const dispatch = useAppDispatch();

  const handleTableChange = (pagination: Pagination) => {
    setPage(pagination);
    query.set(TABLES.page, `${pagination?.current}`);
    navigate(`?${query.toString()}`);
  };

  const handleEdit = (id: string) => {
    dispatch(setSocialLinkEditData(SOCIAL_ICONS_DATA));
    navigate(`edit/${id}`);
  };

  const handleDeleteRow = (id: string) => {
    setIsModalActive(true);
    setDeleteId(Number(id));
  };

  const handleDeleteIcon = () => {
    dispatch(deleteSocialIcon({ id: Number(deleteId) }));
    setIsModalActive(false);
  };

  const columns = [
    {
      title: t('common.icon'),
      dataIndex: 'icon',
      key: 'icon',
      width: '25%',
      render: (text: string, record: ISocialLinks) => {
        return (
          <BaseRow>
            <BaseImage
              src={`${imageUrl}${record.icon}`}
              alt="article"
              preview={false}
              crossOrigin="anonymous"
              width={45}
              height={45}
            />
          </BaseRow>
        );
      },
    },
    {
      title: t('common.title'),
      dataIndex: 'title',
      key: 'title',
      width: '25%',
      render: (text: string, record: ISocialLinks) => {
        return (
          <BaseRow>
            <span className="cut_text">{record.title}</span>
          </BaseRow>
        );
      },
    },
    {
      title: t('common.link'),
      dataIndex: 'link',
      key: 'link',
      width: '25%',
      render: (text: string, record: ISocialLinks) => {
        return (
          <BaseTooltip title={record.link} color="black">
            <BaseRow>
              <span className="cut_text">{record.link}</span>
            </BaseRow>
          </BaseTooltip>
        );
      },
    },
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '25%',
      render: (text: string, record: IBody) => {
        return (
          <BaseSpace>
            <BaseButton type="ghost" onClick={() => handleEdit(record.id)}>
              {t('common.edit')}
            </BaseButton>
            <BaseButton type="default" danger onClick={() => handleDeleteRow(record.id)}>
              {t('tables.delete')}
            </BaseButton>
          </BaseSpace>
        );
      },
    },
  ];

  useEffect(() => {
    if (isMounted) {
      dispatch(getSocialIcons());
    }
  }, []);

  return (
    <BaseForm form={form} component={false}>
      <BaseTable
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
      />
      <BaseModal
        title={t('modals.userActivation')}
        open={isModalActive}
        onOk={handleDeleteIcon}
        onCancel={() => setIsModalActive(false)}
      >
        <p>{t('modals.delete_icon')}</p>
      </BaseModal>
    </BaseForm>
  );
};
