import React, { useState, useEffect } from 'react';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { Pagination } from 'api/table.api';
import { useMounted } from '@app/hooks/useMounted';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CHANGE_STATUS_BUTTON, SHOW_SEE_CHAT_BUTTON, TABLES, TRADE_STATUSES } from '@app/constants/tables';
import { useTranslation } from 'react-i18next';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { changeRequestStatus, getTradeRequests } from '@app/store/actions/trades';
import { ITradeRequest } from '@app/api/trades.api';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { Chat } from '../chat';
import { setChatMessages, setTradeRequest } from '@app/store/slices/tradesSlice';
import { BaseDropdown } from '@app/components/common/BaseDropdown/Dropdown';
import { DownOutlined } from '@ant-design/icons';
import { socket } from '@app/socket';

import armenianCurrencyIcon from '@app/assets/icons/armenianCurrency.svg';

export const TradeDetailsTreeTable: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedRequestId, setSelectedRequestId] = useState<number | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const [selectedRoomTitle, setSelectedRoomTitle] = useState<string>('');
  const [selectedRoomId, setSelectedRoomId] = useState<string>('');
  const [openChangeStatusModal, setOpenChangeStatusModal] = useState<boolean>(false);

  const [form] = BaseForm.useForm();

  const requests = useAppSelector((state) => state.trades.tradeRequests);
  const loading = useAppSelector((state) => state.trades.requestLoading);
  const total = useAppSelector((state) => state.trades.requestTotal);
  const priceRate = useAppSelector((state) => state.trades.priceRate);
  const user = useAppSelector((state) => state.user.user);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { trade_id } = useParams();
  const { state } = useLocation();

  const query = new URLSearchParams(useLocation().search);
  const selectedPage = query.get(TABLES.page);

  const [page, setPage] = useState<Pagination>({
    current: selectedPage ? Number(selectedPage) : TABLES.first_page,
    pageSize: TABLES.per_page,
    total: Number(total),
  });

  const { isMounted } = useMounted();
  const dispatch = useAppDispatch();

  const handleTableChange = (pagination: Pagination) => {
    setPage(pagination);
    query.set(TABLES.page, `${pagination?.current}`);
    navigate(`?${query.toString()}`);
  };

  const handleSeeChat = (data: ITradeRequest) => {
    dispatch(setChatMessages([]));
    dispatch(setTradeRequest(data));
    setIsModalOpen(true);
  };

  const statusNames = [
    {
      key: 'rejected',
      label: t('tables.reject'),
    },
    {
      key: 'completed',
      label: t('dropdowns.complete'),
    },
  ];

  const handleChangeStatus = (key: string, id: number, roomTitle: string, roomId: string | null) => {
    setSelectedRequestId(id);
    setSelectedStatus(key);
    setOpenChangeStatusModal(true);
    setSelectedRoomTitle(roomTitle);
    setSelectedRoomId(roomId ?? '');
  };

  const handleChangeStatusRequest = () => {
    const data = {
      status: selectedStatus,
    };

    socket.emit('joinRoom', selectedRoomTitle);

    const messageData = {
      room_id: Number(selectedRoomId),
      roomTitle: selectedRoomTitle,
      sender_id: user?.id,
      message: '',
      created_at: new Date(),
      attachmentPaths: [],
      is_admin: true,
      sender_name: `${user?.firstName}`,
      is_arbitraged: false,
      request_state: '',
      admin_action: selectedStatus,
    };

    socket.emit('send_message', messageData);

    const params = {
      page: `${page.current}`,
      per_page: `${page.pageSize}`,
    };
    dispatch(changeRequestStatus({ id: Number(selectedRequestId), data, params, trade_id: trade_id as string }));
    setSelectedRequestId(null);
    setSelectedStatus('');
    setSelectedRoomTitle('');
    setSelectedRoomId('');
    setOpenChangeStatusModal(false);
  };

  const requestsColumns = [
    {
      title: t('common.request_id'),
      dataIndex: 'requester',
      key: 'requester',
      render: (text: string, record: ITradeRequest) => {
        return (
          <BaseRow>
            <span className="cut_text_small">{record.id}</span>
          </BaseRow>
        );
      },
    },
    {
      title: t('common.nickname'),
      dataIndex: 'requester',
      key: 'requester',
      render: (text: string, record: ITradeRequest) => {
        return (
          <BaseRow>
            <span className="cut_text_small">{record.nickname}</span>
          </BaseRow>
        );
      },
    },
    {
      title: t('common.amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: (text: string, record: ITradeRequest) => {
        return (
          <BaseRow>
            <span className="cut_text_small">{record.amount}</span>
          </BaseRow>
        );
      },
    },
    {
      title: t('common.userRateInAmd'),
      dataIndex: 'priceRate',
      key: 'priceRate',
      render: (text: string, record: ITradeRequest) => {
        return (
          <BaseRow>
            <span className="cut_text_small">{priceRate}</span>
            <img src={armenianCurrencyIcon} alt="Armenian Valuta" />
          </BaseRow>
        );
      },
    },
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      width: '15%',
      render: (text: string, record: ITradeRequest) => {
        return (
          <div className="action_field">
            {SHOW_SEE_CHAT_BUTTON.includes(record.merchant_status) && (
              <BaseSpace>
                <BaseButton type="ghost" onClick={() => handleSeeChat(record)}>
                  {t('tables.seeChat')}
                </BaseButton>
              </BaseSpace>
            )}
            {!state?.isClosed && (
              <BaseSpace>
                <BaseDropdown
                  menu={{
                    items: statusNames,
                    onClick: (event) => {
                      handleChangeStatus(event.key, record.id, record?.roomTitle, record?.roomId);
                    },
                  }}
                  trigger={['click']}
                >
                  <BaseButton onClick={(e) => e.preventDefault()}>
                    {t('dropdowns.changeStatus')} <DownOutlined />
                  </BaseButton>
                </BaseDropdown>
              </BaseSpace>
            )}
            {record?.is_arbitraged && !state?.isClosed && (
              <BaseSpace>
                <BaseButton severity="success" onClick={() => handleSeeChat(record)}>
                  {t('tables.joinChat')}
                </BaseButton>
              </BaseSpace>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (selectedPage && Number(selectedPage) !== page.current) {
      setPage((state) => ({
        ...state,
        current: Number(selectedPage),
      }));
    }
  }, [selectedPage]);

  useEffect(() => {
    setPage((state) => ({ ...state, total: Number(total) }));
  }, [total]);

  useEffect(() => {
    if (isMounted) {
      const params = {
        page: `${page.current}`,
        per_page: `${page.pageSize}`,
      };
      dispatch(getTradeRequests({ params, id: trade_id as string }));
    }
  }, [page]);

  return (
    <BaseForm form={form} component={false}>
      <BaseTable
        columns={requestsColumns}
        dataSource={requests}
        pagination={page}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
      />
      <BaseModal title={t('modals.chat_modal')} open={isModalOpen} onCancel={() => setIsModalOpen(false)} footer={[]}>
        <Chat />
      </BaseModal>
      <BaseModal
        title={t('modals.userActivation')}
        open={openChangeStatusModal}
        onOk={handleChangeStatusRequest}
        onCancel={() => setOpenChangeStatusModal(false)}
      >
        <p>{t('modals.closeTradeMessage')}</p>
      </BaseModal>
    </BaseForm>
  );
};
