import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import NftDashboardPage from '@app/pages/DashboardPages/NftDashboardPage';
import MainSection from '@app/pages/Content/MainSection';
import ForMerchants from '@app/pages/Content/ForMerchants';
import AboutUs from '@app/pages/Content/AboutUs';
import PrivacyPolicy from '@app/pages/Content/PrivacyPolicy';
import TermsOfUse from '@app/pages/Content/TermsOfUse';
import ContactUs from '@app/pages/Content/ContactUs';
import Faq from '@app/pages/Content/Faq/Faq';
import CreateEditFaq from '@app/pages/Content/Faq/CreateEditFaq';
import SocialIcons from '@app/pages/Content/SocialIcons/SocialIcons';
import CreateEditSocial from '@app/pages/Content/SocialIcons/CreateEditSocial';
import OngoingTrades from '@app/pages/trades/ongoingTrades/OngoingTrades';
import TradesHistory from '@app/pages/trades/tradesHistory/TradesHistory';
import TradeDetails from '@app/pages/trades/tradeDetails/TradeDetails';
import { UserDetails } from '@app/pages/userDetails';
import ChangePassword from '@app/pages/changePassword';
import { socket } from '@app/socket';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setCurrenciesRate } from '@app/store/slices/contentSlice';

const DataTablesPage = React.lazy(() => import('@app/pages/DataTablesPage'));
const UsersPage = React.lazy(() => import('@app/pages/users/Users'));
const MerchantsPage = React.lazy(() => import('@app/pages/merchants/Merchants'));

const Logout = React.lazy(() => import('./Logout'));

export const NFT_DASHBOARD_PATH = '/';
export const MEDICAL_DASHBOARD_PATH = '/medical-dashboard';

const NftDashboard = withLoading(NftDashboardPage);

const DataTables = withLoading(DataTablesPage);
const Users = withLoading(UsersPage);
const Merchants = withLoading(MerchantsPage);
const MainSectionPage = withLoading(MainSection);
const ForMerchantsPage = withLoading(ForMerchants);
const AboutUsPage = withLoading(AboutUs);
const PrivacyPolicyPage = withLoading(PrivacyPolicy);
const TermsOfUsePage = withLoading(TermsOfUse);
const ContactUsPage = withLoading(ContactUs);
const FaqPage = withLoading(Faq);
const CreateEditFaqPage = withLoading(CreateEditFaq);
const SocialIconsPage = withLoading(SocialIcons);
const CreateEditSocialPage = withLoading(CreateEditSocial);
const OngoingTradesPage = withLoading(OngoingTrades);
const TradesHistoryPage = withLoading(TradesHistory);
const TradeDetailsPage = withLoading(TradeDetails);
const UserDetailsPage = withLoading(UserDetails);
const ChangePasswordPage = withLoading(ChangePassword);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC = () => {
  const dispatch = useAppDispatch();

  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  useEffect(() => {
    socket.on('connect', () => undefined);

    socket.on('currency_data', (data) => {
      dispatch(setCurrenciesRate(data));
    });

    socket.on('disconnect', () => undefined);

    socket.on('error', (error) => undefined);

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('error');
      socket.off('websocket_data');
    };
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path={NFT_DASHBOARD_PATH} element={protectedLayout}>
          <Route index element={<NftDashboard />} />
          <Route path="clients" element={<Users />} />
          <Route path="merchants" element={<Merchants />} />
          <Route path="data-tables" element={<DataTables />} />
          <Route path="content/main-section" element={<MainSectionPage />} />
          <Route path="content/for-merchants" element={<ForMerchantsPage />} />
          <Route path="content/about-us" element={<AboutUsPage />} />
          <Route path="content/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="content/terms-of-use" element={<TermsOfUsePage />} />
          <Route path="content/contact-information" element={<ContactUsPage />} />
          <Route path="content/faq" element={<FaqPage />} />
          <Route path="content/faq/create" element={<CreateEditFaqPage />} />
          <Route path="content/faq/edit/:id" element={<CreateEditFaqPage />} />
          <Route path="content/social-links" element={<SocialIconsPage />} />
          <Route path="content/social-links/create" element={<CreateEditSocialPage />} />
          <Route path="content/social-links/edit/:id" element={<CreateEditSocialPage />} />
          <Route path="trades/ongoing-trades" element={<OngoingTradesPage />} />
          <Route path="trades/trades-history" element={<TradesHistoryPage />} />
          <Route path="trades/details/:trade_id" element={<TradeDetailsPage />} />
          <Route path="user-details/:id" element={<UserDetailsPage />} />
          <Route path="/change-password" element={<ChangePasswordPage />} />
          <Route path="/*" element={<Navigate to={NFT_DASHBOARD_PATH} replace />} />
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
        <Route path="/*" element={<Navigate to="/auth/login" replace />} />
      </Routes>
    </BrowserRouter>
  );
};
