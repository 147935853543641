import React from 'react';

export const ClearIcon: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_587_30291)">
      <path
        d="M16.5408 4.22169L15.7783 3.45921C15.6978 3.37901 15.5887 3.33398 15.475 3.33398C15.3613 3.33398 15.2522 3.37901 15.1716 3.45921L10.3025 8.32836C10.2627 8.36828 10.2154 8.39994 10.1634 8.42154C10.1113 8.44315 10.0555 8.45427 9.99915 8.45427C9.94279 8.45427 9.88699 8.44315 9.83494 8.42154C9.78288 8.39994 9.73562 8.36828 9.69583 8.32836L4.82665 3.45921C4.74606 3.37901 4.63702 3.33398 4.52333 3.33398C4.40964 3.33398 4.30056 3.37901 4.21998 3.45921L3.4575 4.22169C3.3773 4.30227 3.33228 4.41135 3.33228 4.52503C3.33228 4.63872 3.3773 4.74777 3.4575 4.82835L8.32666 9.69754C8.36657 9.73733 8.39823 9.78459 8.41984 9.83664C8.44144 9.8887 8.45257 9.9445 8.45257 10.0009C8.45257 10.0572 8.44144 10.113 8.41984 10.1651C8.39823 10.2171 8.36657 10.2644 8.32666 10.3042L3.4575 15.1734C3.3773 15.2539 3.33228 15.363 3.33228 15.4767C3.33228 15.5904 3.3773 15.6994 3.4575 15.78L4.21998 16.5425C4.25977 16.5824 4.30706 16.6141 4.35912 16.6357C4.41117 16.6573 4.46697 16.6684 4.52333 16.6684C4.57968 16.6684 4.63548 16.6573 4.68754 16.6357C4.73959 16.6141 4.78685 16.5824 4.82665 16.5425L9.69583 11.6734C9.77641 11.5932 9.88546 11.5481 9.99915 11.5481C10.1128 11.5481 10.2219 11.5932 10.3025 11.6734L15.1716 16.5425C15.2114 16.5824 15.2587 16.6141 15.3108 16.6357C15.3628 16.6573 15.4186 16.6684 15.475 16.6684C15.5314 16.6684 15.5872 16.6573 15.6392 16.6357C15.6913 16.6141 15.7385 16.5824 15.7783 16.5425L16.5408 15.78C16.5807 15.7402 16.6124 15.693 16.634 15.6409C16.6556 15.5889 16.6667 15.5331 16.6667 15.4767C16.6667 15.4203 16.6556 15.3645 16.634 15.3125C16.6124 15.2604 16.5807 15.2131 16.5408 15.1734L11.6717 10.3042C11.5915 10.2236 11.5464 10.1145 11.5464 10.0009C11.5464 9.88717 11.5915 9.77812 11.6717 9.69754L16.5408 4.82835C16.5807 4.78856 16.6124 4.7413 16.634 4.68924C16.6556 4.63719 16.6667 4.58139 16.6667 4.52503C16.6667 4.46868 16.6556 4.41288 16.634 4.36082C16.6124 4.30877 16.5807 4.26148 16.5408 4.22169Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_587_30291">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
