import { IError } from '@app/api/auth.api';
import {
  IBody,
  IInviteMerchant,
  IUsersParams,
  IUsersParamsQuery,
  IVerifyUser,
  acceptRejectMerchantApi,
  blockUserApi,
  getInvitationsApi,
  getMerchantsApi,
  getRequestedUserApi,
  getUserBalanceHistoryApi,
  getUserByIdApi,
  getUsersApi,
  inviteMerchantApi,
  resetUserTwoFactorApi,
  verifyUserByAdminApi,
} from '@app/api/table.api';
import { TABLES } from '@app/constants/tables';
import { notificationController } from '@app/controllers/notificationController';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getUsers = createAsyncThunk('getUsers', async ({ params }: IUsersParamsQuery, { rejectWithValue }) => {
  try {
    const { data } = await getUsersApi(params);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const blockUser = createAsyncThunk(
  'blockUser',
  async ({ id, params }: { id: number; params: IUsersParams }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await blockUserApi(id);
      notificationController.success({ message: data.message });
      dispatch(getUsers({ params }));
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getMerchants = createAsyncThunk(
  'getMerchants',
  async ({ params }: IUsersParamsQuery, { rejectWithValue }) => {
    try {
      const { data } = await getMerchantsApi(params);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getMerchantUsers = createAsyncThunk(
  'getMerchantUsers',
  async ({ params }: IUsersParamsQuery, { rejectWithValue }) => {
    try {
      const { data } = await getUsersApi(params);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const blockMerchants = createAsyncThunk(
  'blockMerchants',
  async ({ id, params }: { id: number; params: IUsersParams }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await blockUserApi(id);
      notificationController.success({ message: data.message });
      dispatch(getMerchantUsers({ params }));
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const acceptRejectMerchant = createAsyncThunk(
  'acceptRejectMerchant',
  async ({ data, params }: { data: IBody; params: IUsersParams }, { rejectWithValue, dispatch }) => {
    try {
      await acceptRejectMerchantApi(data);
      dispatch(getMerchants({ params }));
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getInvitationsList = createAsyncThunk(
  'getInvitationsList',
  async ({ params }: { params: IUsersParams }, { rejectWithValue, dispatch }) => {
    try {
      const res = await getInvitationsApi(params);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const inviteMerchant = createAsyncThunk(
  'inviteMerchant',
  async ({ data, handleAction }: IInviteMerchant, { rejectWithValue, dispatch }) => {
    try {
      const res = await inviteMerchantApi(data);
      dispatch(getInvitationsList({ params: { page: TABLES.first_page, per_page: TABLES.per_page } }));
      notificationController.success({ message: res.data.message });
      if (handleAction) handleAction();
    } catch (error) {
      const typedError = error as IError;
      notificationController.error({ message: typedError.response.data.message });
      return rejectWithValue(error);
    }
  },
);

export const getUserById = createAsyncThunk('getUserById', async ({ id }: { id: number }, { rejectWithValue }) => {
  try {
    const { data } = await getUserByIdApi(id);
    return data?.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getRequestedUser = createAsyncThunk(
  'getRequestedUser',
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const { data } = await getRequestedUserApi(id);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getUserBalanceHistory = createAsyncThunk(
  'getUserBalanceHistoryApi',
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const { data } = await getUserBalanceHistoryApi(id);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const resetUserTwoFactor = createAsyncThunk(
  'resetUserTwoFactor',
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const res = await resetUserTwoFactorApi(id);
      notificationController.success({ message: res.data.message });
    } catch (error) {
      const typedError = error as IError;
      notificationController.error({ message: typedError.response.data.message });
      return rejectWithValue(error);
    }
  },
);

export const verifyUserByAdmin = createAsyncThunk(
  'verifyUserByAdmin',
  async ({ id, data, params }: IVerifyUser, { rejectWithValue, dispatch }) => {
    try {
      const res = await verifyUserByAdminApi(id, data);
      notificationController.success({ message: res.data.message });
      dispatch(getUsers({ params }));
    } catch (error) {
      const typedError = error as IError;
      notificationController.error({ message: typedError.response.data.message });
      return rejectWithValue(error);
    }
  },
);
