// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.change_password_field {
  width: 50%;
  margin: auto;
}

.change_password_title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
}

@media (max-width: 768px) {
  .change_password_field {
    width: 90%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/changePassword/style.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".change_password_field {\n  width: 50%;\n  margin: auto;\n}\n\n.change_password_title {\n  text-align: center;\n  margin-bottom: 20px;\n  font-size: 24px;\n}\n\n@media (max-width: 768px) {\n  .change_password_field {\n    width: 90%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
