export const LANGUAGE_TABS = {
  hy: 'hy',
  ru: 'ru',
  us: 'en',
};

export const NOTIFY_MESSAGE = {
  success_update: 'Updated Successfully',
  success_create: 'Created Successfully',
  success_delete: 'Deleted Successfully',
};

export const CONTENT_KEYS = {
  content_en: 'content_en',
  content_hy: 'content_hy',
  content_ru: 'content_ru',
};

export const SOCIAL_ICONS_DATA = {
  icon: '',
  link: '',
  title: '',
  id: null,
};

export const FAQ_DATA = {
  answer_en: '',
  answer_hy: '',
  answer_ru: '',
  question_en: '',
  question_hy: '',
  question_ru: '',
};
