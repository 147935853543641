import { IChangePassword, IError, LoginRequest, changePasswordApi, getUserApi, login } from '@app/api/auth.api';
import { NOTIFY_MESSAGE } from '@app/constants/content';
import { notificationController } from '@app/controllers/notificationController';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const doLogin = createAsyncThunk('auth/doLogin', async (body: LoginRequest, { rejectWithValue }) => {
  try {
    const { data } = await login(body);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getUser = createAsyncThunk('auth/getUser', async (_, { rejectWithValue }) => {
  try {
    const { data } = await getUserApi();
    return data?.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async ({ data }: IChangePassword, { rejectWithValue }) => {
    try {
      await changePasswordApi(data);
      notificationController.success({ message: NOTIFY_MESSAGE.success_update });
    } catch (error) {
      const typedError = error as IError;

      notificationController.error({ message: typedError.response.data.message });
      return rejectWithValue(error);
    }
  },
);
