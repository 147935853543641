import { httpApi } from './http.api';

interface IParams {
  page?: string;
  per_page?: string;
  search?: string;
  filters?: {
    statuses?: string[];
    state?: string;
  };
}

export interface ITradeParams {
  params: IParams;
}

export interface IRequestParams {
  params: IParams;
  id: string;
}

export interface ICloseTrade {
  id: number;
  params: IParams;
}

export interface ITrade {
  amount: string;
  banks: string[];
  created_at: string;
  currency: string;
  firstName: string;
  id: number;
  lastName: string;
  merchant_id: string;
  merchant_rating: number | null;
  priceRate: string;
  status: string;
  type: string;
  currencyLongTitle: string;
  currencyTitle: string;
}

export interface ITradeRequest {
  amount: string;
  created_at: string;
  currency: string;
  currency_id: number;
  id: number;
  merchant_status: string;
  priceRate: string;
  requester: string;
  requester_id: string;
  requester_rating: string;
  roomId: string | null;
  roomTitle: string;
  type: string;
  trade_id: string;
  updated_at: string;
  user_status: string;
  nickname: string;
  is_arbitraged: boolean;
}

export interface IChatMessages {
  created_at: Date;
  id?: number;
  message: string;
  room_id: number;
  sender_id: number | null | undefined;
  roomTitle?: string;
  attachmentPaths: string[] | File[];
  is_admin: boolean;
  sender_name: string;
  is_arbitraged: boolean;
  request_state: string;
  admin_action: string | null;
}

export interface ITradesSlice {
  data: ITradeRequest[];
  tradeLoading: boolean;
  tradeTotal: number;
  tradeRequests: ITradeRequest[];
  requestLoading: boolean;
  requestTotal: number;
  priceRate: string;
  tradeRequest: ITradeRequest;
  messages: IChatMessages[];
}

export interface ITradeChangeStatusData {
  status: string;
}

export interface IChangeStatusRequest {
  id: number;
  data: ITradeChangeStatusData;
  params: IParams;
  trade_id: string;
}

export const getTradesApi = async (params: IParams) => await httpApi.get('/v1/admin/trades', { params });
export const getTradeRequestsApi = async (id: string, params: IParams) =>
  await httpApi.get(`/v1/admin/trades/${id}/requests`, { params });
export const getChatMessagesApi = async (id: number) => await httpApi.get(`/v1/chat/messages/room/${id}`);
export const closeTradeApi = async (id: number) => await httpApi.put(`/v1/admin/trades/${id}/close-trade`);
export const changeRequestStatusApi = async (id: number, body: ITradeChangeStatusData) =>
  await httpApi.put(`/v1/admin/trade-requests/${id}/change-status`, body);
