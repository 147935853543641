import incomeIcon from './images/income.svg';
import outcomeIcon from './images/outcome.svg';
import transferIcon from './images/transfer.svg';
import depositIcon from './images/deposit.svg';
import withdrawIcon from './images/withdraw.svg';

export const TABLES = {
  page: 'page',
  user_role: 'user',
  merchant_role: 'merchant',
  per_page: 15,
  first_page: 1,
  search: 'search',
};

export const TAB_VALUES = {
  approved: 'approved',
  requested: 'requested',
  invitation: 'invitation',
};

export const ACCEPT_REJECT_TYPES = {
  reject: 'reject',
  accept: 'accept',
};

export const TRADES_TABES = {
  active: 'active',
  inactive: 'inactive',
};

export const TRADE_STATES = {
  close: 'close',
  open: 'open',
};

export const SHOW_SEE_CHAT_BUTTON = ['rejected', 'closed', 'completed'];
export const CHANGE_STATUS_BUTTON = ['pending', 'accepted'];

export const TRADE_STATUSES = {
  completed: 'completed',
};

export const BALANCE_TYPES: { [key: string]: { [key: string]: string } } = {
  out: {
    title: 'Outcome',
    icon: outcomeIcon,
  },
  in: {
    title: 'Income',
    icon: incomeIcon,
  },
};

export const BALANCE_SOURCE: { [key: string]: { [key: string]: string } } = {
  withdraw: {
    title: 'Withdraw',
    icon: withdrawIcon,
  },
  transaction: {
    title: 'Transfer',
    icon: transferIcon,
  },
  deposit: {
    title: 'Deposit',
    icon: depositIcon,
  },
};

export const MESSAGE_NOT_TYPES: { [key: string]: string } = {
  completed: 'The user confirmed the trade',
  rejected: 'The user rejected the trade',
};

export const ADMIN_ACTION_MESSAGE: { [key: string]: string } = {
  completed: 'You confirmed the trade',
  rejected: 'You rejected the trade',
};
