import React, { useState, useEffect } from 'react';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { Pagination } from 'api/table.api';
import { useMounted } from '@app/hooks/useMounted';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { TABLES, TRADE_STATES } from '@app/constants/tables';
import { useTranslation } from 'react-i18next';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseTooltip } from '@app/components/common/BaseTooltip/BaseTooltip';
import { getTrades } from '@app/store/actions/trades';
import { ITrade } from '@app/api/trades.api';

import armenianCurrencyIcon from '@app/assets/icons/armenianCurrency.svg';

export const TradesHistoryTreeTable: React.FC = () => {
  const [form] = BaseForm.useForm();

  const trades = useAppSelector((state) => state.trades.data);
  const loading = useAppSelector((state) => state.trades.tradeLoading);
  const total = useAppSelector((state) => state.trades.tradeTotal);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const query = new URLSearchParams(useLocation().search);
  const selectedPage = query.get(TABLES.page);
  const search = query.get(TABLES.search);

  const [page, setPage] = useState<Pagination>({
    current: selectedPage ? Number(selectedPage) : TABLES.first_page,
    pageSize: TABLES.per_page,
    total: Number(total),
  });

  const { isMounted } = useMounted();
  const dispatch = useAppDispatch();

  const handleTableChange = (pagination: Pagination) => {
    setPage(pagination);
    query.set(TABLES.page, `${pagination?.current}`);
    navigate(`?${query.toString()}`);
  };

  const handleRowClick = (id: number) => {
    navigate(`/trades/details/${id}`, { state: { isClosed: true } });
  };

  const requestsColumns = [
    {
      title: t('common.tradeId'),
      dataIndex: 'id',
      key: 'id',
      render: (text: string, record: ITrade) => {
        return (
          <BaseRow>
            <span className="cut_text_small">{record.id}</span>
          </BaseRow>
        );
      },
    },
    {
      title: t('common.merchantName'),
      dataIndex: 'merchantName',
      key: 'merchantName',
      render: (text: string, record: ITrade) => {
        return (
          <BaseTooltip title={`${record.firstName} ${record.lastName}`} color="black">
            <BaseRow>
              <span className="cut_text_small">{`${record.firstName} ${record.lastName}`}</span>
            </BaseRow>
          </BaseTooltip>
        );
      },
    },
    {
      title: t('common.currency'),
      dataIndex: 'currencyLongTitle',
      key: 'currencyLongTitle',
      render: (text: string, record: ITrade) => {
        return (
          <BaseRow>
            <span className="cut_text">{record.currencyLongTitle}</span>
          </BaseRow>
        );
      },
    },
    {
      title: t('common.amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: (text: string, record: ITrade) => {
        return <BaseRow>{record.amount}</BaseRow>;
      },
    },
    {
      title: t('common.userRateInAmd'),
      dataIndex: 'priceRate',
      key: 'priceRate',
      render: (text: string, record: ITrade) => {
        return (
          <BaseRow>
            <span>{record.priceRate}</span>
            <img src={armenianCurrencyIcon} alt="Armenian Valuta" />
          </BaseRow>
        );
      },
    },
  ];

  useEffect(() => {
    if (selectedPage && Number(selectedPage) !== page.current) {
      setPage((state) => ({
        ...state,
        current: Number(selectedPage),
      }));
    }
  }, [selectedPage]);

  useEffect(() => {
    setPage((state) => ({ ...state, total: Number(total) }));
  }, [total]);

  useEffect(() => {
    if (isMounted) {
      const params = {
        page: `${page.current}`,
        per_page: `${page.pageSize}`,
        search: search ?? '',
        filters: {
          state: TRADE_STATES.close,
        },
      };
      dispatch(getTrades({ params }));
    }
  }, [page, search]);

  return (
    <BaseForm form={form} component={false}>
      <BaseTable
        columns={requestsColumns}
        dataSource={trades}
        pagination={page}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        onRow={(record) => {
          return {
            onClick: () => handleRowClick(record.id),
          };
        }}
      />
    </BaseForm>
  );
};
