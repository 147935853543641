import { httpApi, httpFormApi } from './http.api';

export interface IMainSection {
  title_hy: string;
  title_ru: string;
  title_en: string;
  content_hy: string;
  content_ru: string;
  content_en: string;
}

export interface IContactUs {
  phone: string;
  address: string;
  email: string;
}

export interface IFaqData {
  answer_en: string;
  answer_hy: string;
  answer_ru: string;
  question_en: string;
  question_hy: string;
  question_ru: string;
}

export interface ISocialLinks {
  icon: string;
  id: number | null;
  link: string;
  title: string;
}

export interface ICurrencyRateElement {
  currentRate: number;
  differencePercent: number;
  direction?: number;
}

export interface ICurrenciesRate {
  [key: string]: ICurrencyRateElement;
}
export interface IContentSlice {
  mainSection: IMainSection;
  mainSectionLoading: boolean;
  forMerchants: IMainSection;
  forMerchantsLoading: boolean;
  aboutUs: IMainSection;
  aboutUsLoading: boolean;
  privacyPolicy: IMainSection;
  privacyPolicyLoading: boolean;
  termsOfUse: IMainSection;
  termsOfUseLoading: boolean;
  contactUs: IContactUs;
  contactUsLoading: boolean;
  faqData: IFaqData[];
  faqTotal: number | null;
  faqLoading: boolean;
  faqEditData: IFaqData;
  socialLinks: ISocialLinks[];
  socialLinksLoading: boolean;
  socialLinkEditData: ISocialLinks;
  currenciesRate: ICurrenciesRate;
}

export interface IBody {
  [key: string]: string;
}

export interface IMainSectionData {
  data: IBody;
}

export interface IUpdateId {
  id: number;
}

export type IUpdateData = IMainSectionData & IUpdateId;

export interface IFormData {
  [key: string]: FormDataEntryValue;
}

export interface IFormDataTypes {
  data: IFormData;
}

export interface INavigate {
  handleNavigate: () => void;
}

export interface IFaqParams {
  page?: number;
  per_page?: number;
  language: string;
}

export interface IFaqParamsQuery {
  params: IFaqParams;
}

export type IFaqIdUpdate = IFaqParamsQuery & IUpdateId;

export type ISocialFormTypes = IFormDataTypes & INavigate;

export type ISocialUpdate = IFormDataTypes & IUpdateId & INavigate;

export const getMainSectionApi = async () => await httpApi.get('/v1/content/home-page');
export const updateMainSectionApi = async (body: IBody) => await httpApi.put('/v1/content/home-page', body);
export const createMainSectionApi = async (body: IBody) => await httpApi.post('/v1/content/home-page', body);

export const getForMerchantsApi = async () => await httpApi.get('/v1/content/merchant-page');
export const updateForMerchantsApi = async (body: IBody) => await httpApi.put('/v1/content/merchant-page', body);
export const createForMerchantsApi = async (body: IBody) => await httpApi.post('/v1/content/merchant-page', body);

export const getAboutUsApi = async () => await httpApi.get('/v1/content/about-us');
export const updateAboutUsApi = async (body: IBody) => await httpApi.put('/v1/content/about-us', body);
export const createAboutUsApi = async (body: IBody) => await httpApi.post('/v1/content/about-us', body);

export const getPrivacyPolicyApi = async () => await httpApi.get('/v1/content/privacy-policies');
export const updatePrivacyPolicyApi = async (body: IBody) => await httpApi.put('/v1/content/privacy-policies', body);
export const createPrivacyPolicyApi = async (body: IBody) => await httpApi.post('/v1/content/privacy-policies', body);

export const getTermsOfUseApi = async () => await httpApi.get('/v1/content/terms');
export const updateTermsOfUseApi = async (body: IBody) => await httpApi.put('/v1/content/terms', body);
export const createTermsOfUseApi = async (body: IBody) => await httpApi.post('/v1/content/terms', body);

export const getContactUsApi = async () => await httpApi.get('/v1/content/contacts');
export const updateContactUsApi = async (body: IBody) => await httpApi.put('/v1/content/contacts', body);
export const createContactUsApi = async (body: IBody) => await httpApi.post('/v1/content/contacts', body);

export const getFaqApi = async (params: IFaqParams) => await httpApi.get('/v1/content/faqs', { params });
export const getFaqByIdApi = async (params: IFaqParams, id: number) =>
  await httpApi.get(`/v1/content/faqs/${id}`, { params });
export const updateFaqApi = async (body: IBody, id: number) => await httpApi.put(`/v1/content/faqs/${id}`, body);
export const createFaqApi = async (body: IBody) => await httpApi.post('/v1/content/faqs', body);
export const deleteFaqApi = async (id: number) => await httpApi.delete(`/v1/content/faqs/${id}`);

export const getSocialIconsApi = async () => await httpApi.get('/v1/content/social-links');
export const getSocialIconByIdApi = async (id: number) => await httpApi.get(`/v1/content/social-links/${id}`);
export const updateSocialIconsApi = async (body: IFormData, id: number) =>
  await httpFormApi.put(`/v1/content/social-links/${id}`, body);
export const createSocialIconsApi = async (body: IFormData) => await httpFormApi.post('/v1/content/social-links', body);
export const deleteSocialIconApi = async (id: number) => await httpApi.delete(`/v1/content/social-links/${id}`);
