import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './Tables.styles';
import { FaqTreeTable } from '../TreeTable/FaqTreeTable';

export const FaqTable: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <S.TablesWrapper>
        <S.Card id="tree-table" title={t('common.faqPage')} padding="1.25rem 1.25rem 0">
          <FaqTreeTable />
        </S.Card>
      </S.TablesWrapper>
    </>
  );
};
