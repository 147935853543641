import { createSlice } from '@reduxjs/toolkit';
import { IContentSlice } from '@app/api/content.api';
import {
  getMainSectionData,
  getForMerchantsData,
  getAboutUs,
  getPrivacyPolicy,
  getTermsOfUse,
  getContactUs,
  getFaqData,
  getSocialIcons,
  getSocialIconById,
  getFaqDataById,
} from '../actions';
import { FAQ_DATA, SOCIAL_ICONS_DATA } from '@app/constants/content';

const initialState: IContentSlice = {
  mainSection: {
    title_hy: '',
    title_ru: '',
    title_en: '',
    content_hy: '',
    content_ru: '',
    content_en: '',
  },
  mainSectionLoading: false,
  forMerchants: {
    title_hy: '',
    title_ru: '',
    title_en: '',
    content_hy: '',
    content_ru: '',
    content_en: '',
  },
  forMerchantsLoading: false,
  aboutUs: {
    title_hy: '',
    title_ru: '',
    title_en: '',
    content_hy: '',
    content_ru: '',
    content_en: '',
  },
  aboutUsLoading: false,
  privacyPolicy: {
    title_hy: '',
    title_ru: '',
    title_en: '',
    content_hy: '',
    content_ru: '',
    content_en: '',
  },
  privacyPolicyLoading: false,
  termsOfUse: {
    title_hy: '',
    title_ru: '',
    title_en: '',
    content_hy: '',
    content_ru: '',
    content_en: '',
  },
  termsOfUseLoading: false,
  contactUs: {
    phone: '',
    address: '',
    email: '',
  },
  contactUsLoading: false,
  faqData: [],
  faqTotal: null,
  faqLoading: false,
  faqEditData: FAQ_DATA,
  socialLinks: [],
  socialLinksLoading: false,
  socialLinkEditData: SOCIAL_ICONS_DATA,
  currenciesRate: {
    BTC: {
      currentRate: 0,
      differencePercent: 0,
      direction: 0,
    },
    ETH: {
      currentRate: 0,
      differencePercent: 0,
      direction: 0,
    },
  },
};

const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    setSocialLinkEditData: (state, { payload }) => {
      state.socialLinkEditData = payload;
    },
    setFaqEditData: (state, { payload }) => {
      state.faqEditData = payload;
    },
    setCurrenciesRate: (state, { payload }) => {
      state.currenciesRate = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMainSectionData.pending, (state) => {
        state.mainSectionLoading = true;
      })
      .addCase(getMainSectionData.fulfilled, (state, { payload }) => {
        state.mainSection = payload?.data?.data;
        state.mainSectionLoading = false;
      })
      .addCase(getMainSectionData.rejected, (state) => {
        state.mainSectionLoading = false;
      });
    builder
      .addCase(getForMerchantsData.pending, (state) => {
        state.forMerchantsLoading = true;
      })
      .addCase(getForMerchantsData.fulfilled, (state, { payload }) => {
        state.forMerchants = payload?.data?.data;
        state.forMerchantsLoading = false;
      })
      .addCase(getForMerchantsData.rejected, (state) => {
        state.forMerchantsLoading = false;
      });
    builder
      .addCase(getAboutUs.pending, (state) => {
        state.aboutUsLoading = true;
      })
      .addCase(getAboutUs.fulfilled, (state, { payload }) => {
        state.aboutUs = payload?.data?.data;
        state.aboutUsLoading = false;
      })
      .addCase(getAboutUs.rejected, (state) => {
        state.aboutUsLoading = false;
      });
    builder
      .addCase(getPrivacyPolicy.pending, (state) => {
        state.privacyPolicyLoading = true;
      })
      .addCase(getPrivacyPolicy.fulfilled, (state, { payload }) => {
        state.privacyPolicy = payload?.data?.data;
        state.privacyPolicyLoading = false;
      })
      .addCase(getPrivacyPolicy.rejected, (state) => {
        state.privacyPolicyLoading = false;
      });
    builder
      .addCase(getTermsOfUse.pending, (state) => {
        state.termsOfUseLoading = true;
      })
      .addCase(getTermsOfUse.fulfilled, (state, { payload }) => {
        state.termsOfUse = payload?.data?.data;
        state.termsOfUseLoading = false;
      })
      .addCase(getTermsOfUse.rejected, (state) => {
        state.termsOfUseLoading = false;
      });
    builder
      .addCase(getContactUs.pending, (state) => {
        state.contactUsLoading = true;
      })
      .addCase(getContactUs.fulfilled, (state, { payload }) => {
        state.contactUs = payload?.data?.data;
        state.contactUsLoading = false;
      })
      .addCase(getContactUs.rejected, (state) => {
        state.contactUsLoading = false;
      });
    builder
      .addCase(getFaqData.pending, (state) => {
        state.faqLoading = true;
      })
      .addCase(getFaqData.fulfilled, (state, { payload }) => {
        state.faqData = payload?.data?.data;
        state.faqTotal = payload?.data?.total;
        state.faqLoading = false;
      })
      .addCase(getFaqData.rejected, (state) => {
        state.faqLoading = false;
      });
    builder
      .addCase(getSocialIcons.pending, (state) => {
        state.socialLinksLoading = true;
      })
      .addCase(getSocialIcons.fulfilled, (state, { payload }) => {
        state.socialLinks = payload?.data?.data;
        state.socialLinksLoading = false;
      })
      .addCase(getSocialIcons.rejected, (state) => {
        state.socialLinksLoading = false;
      });
    builder
      .addCase(getSocialIconById.pending, (state) => {
        state.socialLinksLoading = true;
      })
      .addCase(getSocialIconById.fulfilled, (state, { payload }) => {
        state.socialLinkEditData = payload?.data?.data;
        state.socialLinksLoading = false;
      })
      .addCase(getSocialIconById.rejected, (state) => {
        state.socialLinksLoading = false;
      });
    builder
      .addCase(getFaqDataById.pending, (state) => {
        state.socialLinksLoading = true;
      })
      .addCase(getFaqDataById.fulfilled, (state, { payload }) => {
        state.faqEditData = payload?.data?.data;
        state.socialLinksLoading = false;
      })
      .addCase(getFaqDataById.rejected, (state) => {
        state.socialLinksLoading = false;
      });
  },
});

export const { setSocialLinkEditData, setFaqEditData, setCurrenciesRate } = contentSlice.actions;

export default contentSlice.reducer;
