import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { InboxOutlined } from '@ant-design/icons';
import { ISocialLinks } from '@app/api/content.api';
import { IBody } from '@app/api/table.api';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseImage } from '@app/components/common/BaseImage/BaseImage';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { createSocialIcons, getSocialIconById, getSocialIcons, updateSocialIcons } from '@app/store/actions';
import { FONT_SIZE, FONT_WEIGHT } from '@app/styles/themes/constants';
import { FormProps } from 'antd';
import { PATHNAMES } from '@app/constants/global';

const DraggerIconWrapper = styled.div`
  font-size: 4rem;
  color: var(--primary-color);
`;
const DraggerTitle = styled.div`
  font-size: ${FONT_SIZE.xl};
  font-weight: ${FONT_WEIGHT.bold};
`;
const DraggerDescription = styled.div`
  font-size: ${FONT_SIZE.md};
  padding: 0 1rem;
`;

const imageUrl = process.env.REACT_APP_IMAGE_URL;

const CreateEditSocial = () => {
  const [previewImage, setPreviewImage] = useState<Blob | MediaSource>();

  const data = useAppSelector((state) => state.content.socialLinkEditData);

  const { t } = useTranslation();
  const [form] = BaseForm.useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const handleNavigate = () => {
    navigate(PATHNAMES.social_links);
  };

  const onFinish: FormProps<IBody>['onFinish'] = (values) => {
    const formData = new FormData();

    for (const key in values) {
      formData.append(key, values[key]);
    }

    const requestData = Object.fromEntries(formData);

    if (id) {
      dispatch(updateSocialIcons({ data: requestData, id: Number(id), handleNavigate }));
    } else {
      dispatch(createSocialIcons({ data: requestData, handleNavigate }));
    }
  };

  useEffect(() => {
    if (id) dispatch(getSocialIcons());
  }, []);

  useEffect(() => {
    if (data && id) {
      for (const key in data) {
        const value = key as keyof ISocialLinks;
        form.setFieldValue(key, data[value]);
      }
    }
  }, [data]);

  const uploadProps = {
    name: 'icon',
    multiple: false,
    showUploadList: false,
    maxCount: 1,
    beforeUpload: () => false,
    onChange: (info: any) => {
      setPreviewImage(info.file);
      form.setFieldValue('icon', info.file);
    },
  };

  const normFile = (e = { fileList: [] }) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    if (id) {
      dispatch(getSocialIconById({ id: Number(id) }));
    }
  }, []);

  return (
    <>
      <BaseCol>
        <BaseForm form={form} layout="vertical" name="userForm" onFinish={onFinish}>
          <BaseForm.Item
            name={`title`}
            label={t('common.title')}
            rules={[
              {
                required: true,
                message: t('common.requiredField'),
                whitespace: false,
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <BaseInput placeholder={t('common.title')} />
          </BaseForm.Item>
          <BaseForm.Item
            name={`link`}
            label={t('common.link')}
            rules={[
              {
                required: true,
                message: t('common.requiredField'),
                whitespace: false,
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <BaseInput placeholder={t('common.link')} />
          </BaseForm.Item>

          <BaseForm.Item
            name="icon"
            label={t('common.icon')}
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              {
                required: true,
                type: typeof form.getFieldValue('icon') === 'string' ? 'string' : 'object',
                message: t('common.requiredField'),
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            {(previewImage || data?.icon) && (
              <BaseImage
                src={previewImage ? URL.createObjectURL(previewImage) : `${imageUrl}${data?.icon}`}
                alt="article"
                preview={false}
                crossOrigin="anonymous"
                width={150}
                height={150}
              />
            )}
            <BaseUpload.Dragger {...uploadProps} accept=".png, .jpeg, .svg, .jpg, .webp">
              <DraggerIconWrapper>
                <InboxOutlined />
              </DraggerIconWrapper>
              <DraggerTitle>{t('uploads.dragUpload')}</DraggerTitle>
              <DraggerDescription>{t('uploads.bulkUpload')}</DraggerDescription>
            </BaseUpload.Dragger>
          </BaseForm.Item>
          <BaseButton type="primary" htmlType="submit">
            Save
          </BaseButton>
        </BaseForm>
      </BaseCol>
    </>
  );
};

export default CreateEditSocial;
