import axios from 'axios';
import { deleteToken, readToken } from '@app/services/localStorage.service';

export const httpApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

httpApi.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer ${readToken()}`;

  return config;
});

httpApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      deleteToken();
      if (window.location.pathname !== '/auth/login') window.location.href = '/';
    }
    return Promise.reject(error);
  },
);

export const httpFormApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

httpFormApi.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer ${readToken()}`;
  config.headers['Content-Type'] = 'multipart/form-data';

  return config;
});

export interface ApiErrorData {
  message: string;
}
