import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { OngoingTradesTable } from '@app/components/tables/Tables/OngoingTrades';
import { useTranslation } from 'react-i18next';

const OngoingTrades = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('common.ongoingTrades')}</PageTitle>
      <OngoingTradesTable />
    </>
  );
};

export default OngoingTrades;
