import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { FirstNameItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/FirstNameItem/FirstNameItem';
import { LastNameItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/LastNameItem/LastNameItem';
import { NicknameItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/NicknameItem/NicknameItem';
import { EmailItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/EmailItem/EmailItem';

import { useAppSelector } from '@app/hooks/reduxHooks';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BalanceItem } from './BalanceItem/BalanceItem';
import { TelegramUserName } from './TelegramNameItem/TelegramNameItem';
import { BaseImage } from '@app/components/common/BaseImage/BaseImage';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { BalanceHistoryTable } from '@app/components/tables/TreeTable/BalanceHistoryTable';
import { PhoneItem } from './PhoneItem/PhoneItem';
import { v4 as uuidv4 } from 'uuid';
import { useLocation, useNavigate } from 'react-router-dom';
import { TAB_VALUES } from '@app/constants/tables';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { LeftOutlined } from '@ant-design/icons';

const imageUrl = process.env.REACT_APP_IMAGE_URL;

export const PersonalInfo: React.FC = () => {
  const user = useAppSelector((state) => state.users.userDetails);

  const [isModalActive, setIsModalActive] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string>('');

  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [form] = BaseButtonsForm.useForm();

  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const isAlreadyUser = state === TAB_VALUES.approved;

  const handleOpenPopup = (image: string) => {
    if (isModalActive) {
      setSelectedImage('');
    } else {
      setSelectedImage(image);
    }
    setIsModalActive(!isModalActive);
  };

  const handleOpenClosePopup = () => {
    if (isModalActive) {
      setSelectedImage('');
    }
    setIsModalActive(!isModalActive);
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };

  return (
    <BaseCard>
      <BaseButtonsForm
        form={form}
        name="info"
        loading={isLoading}
        initialValues={user}
        isFieldsChanged={isFieldsChanged}
        setFieldsChanged={setFieldsChanged}
        onFieldsChange={() => setFieldsChanged(true)}
      >
        <BaseRow>
          <BaseButton type="text" onClick={handleNavigateBack} size="small">
            <LeftOutlined />
            <span>Back</span>
          </BaseButton>
        </BaseRow>
        <BaseRow gutter={{ xs: 10, md: 15, xl: 30 }}>
          {user?.passportImage && (
            <>
              <BaseCol span={24}>
                <BaseButtonsForm.Item>
                  <BaseButtonsForm.Title>{t('common.passportImages')}</BaseButtonsForm.Title>
                </BaseButtonsForm.Item>
              </BaseCol>
              <div className="images_field_section">
                {user?.passportImage.map((elem) => (
                  <BaseImage
                    src={`${imageUrl}${elem}`}
                    alt="article"
                    crossOrigin="anonymous"
                    preview={true}
                    width={300}
                    height={300}
                    onClick={() => handleOpenPopup(elem)}
                    key={uuidv4()}
                  />
                ))}
              </div>
            </>
          )}

          <BaseModal
            title={t('modals.passportImage')}
            open={isModalActive}
            onOk={handleOpenClosePopup}
            onCancel={() => setIsModalActive(false)}
            footer={[]}
            width={450}
          >
            <div className="images_modal_section">
              <BaseImage
                src={`${imageUrl}${selectedImage}`}
                alt="article"
                crossOrigin="anonymous"
                preview={false}
                width={400}
                onClick={handleOpenClosePopup}
              />
            </div>
          </BaseModal>

          <BaseCol span={24}>
            <BaseButtonsForm.Item>
              <BaseButtonsForm.Title>{t('profile.nav.personalInfo.title')}</BaseButtonsForm.Title>
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <FirstNameItem />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <LastNameItem />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <EmailItem />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <TelegramUserName />
          </BaseCol>

          {user?.nickname && (
            <BaseCol xs={24} md={12}>
              <NicknameItem />
            </BaseCol>
          )}

          {isAlreadyUser && (
            <BaseCol xs={24} md={12}>
              <BalanceItem />
            </BaseCol>
          )}

          {user?.phoneNumber && (
            <BaseCol xs={24} md={12}>
              <PhoneItem />
            </BaseCol>
          )}
          {isAlreadyUser && (
            <>
              <BaseCol span={24}>
                <BaseButtonsForm.Item>
                  <BaseButtonsForm.Title>{t('common.balanceHistory')}</BaseButtonsForm.Title>
                </BaseButtonsForm.Item>
              </BaseCol>

              <BaseCol span={24}>
                <BalanceHistoryTable />
              </BaseCol>
            </>
          )}
        </BaseRow>
      </BaseButtonsForm>
    </BaseCard>
  );
};
