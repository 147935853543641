import React, { useState, useEffect, useMemo } from 'react';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { Pagination } from 'api/table.api';
import { useMounted } from '@app/hooks/useMounted';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { TABLES, TRADES_TABES, TRADE_STATES } from '@app/constants/tables';
import { useTranslation } from 'react-i18next';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseTabs } from '@app/components/common/BaseTabs/BaseTabs';
import { BaseTooltip } from '@app/components/common/BaseTooltip/BaseTooltip';
import { closeTrade, getTrades } from '@app/store/actions/trades';
import { ITrade } from '@app/api/trades.api';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';

import armenianCurrencyIcon from '@app/assets/icons/armenianCurrency.svg';

export const OngoingTradesTreeTable: React.FC = () => {
  const [form] = BaseForm.useForm();
  const [activeTab, setActiveTab] = useState<string>(TRADES_TABES.active);
  const [openCloseTradeModal, setOpenCloseTradeModal] = useState<boolean>(false);
  const [selectedTradeId, setSelectedTradeId] = useState<number | null>(null);

  const trades = useAppSelector((state) => state.trades.data);
  const loading = useAppSelector((state) => state.trades.tradeLoading);
  const total = useAppSelector((state) => state.trades.tradeTotal);
  const currenciesRate = useAppSelector((state) => state.content.currenciesRate);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const query = new URLSearchParams(useLocation().search);
  const selectedPage = query.get(TABLES.page);
  const search = query.get(TABLES.search);

  const [page, setPage] = useState<Pagination>({
    current: selectedPage ? Number(selectedPage) : TABLES.first_page,
    pageSize: TABLES.per_page,
    total: Number(total),
  });

  const { isMounted } = useMounted();
  const dispatch = useAppDispatch();

  const handleTableChange = (pagination: Pagination) => {
    setPage(pagination);
    query.set(TABLES.page, `${pagination?.current}`);
    navigate(`?${query.toString()}`);
  };

  const handleRowClick = (id: number) => {
    navigate(`/trades/details/${id}`);
  };

  const handleOpenCloseTradeModal = (id: number) => {
    setSelectedTradeId(id);
    setOpenCloseTradeModal(true);
  };

  const handleCloseTrade = () => {
    const params = {
      page: `${page.current}`,
      per_page: `${page.pageSize}`,
      search: search ?? '',
      filters: {
        status: activeTab,
        state: TRADE_STATES.open,
      },
    };
    dispatch(closeTrade({ id: Number(selectedTradeId), params }));
    setSelectedTradeId(null);
    setOpenCloseTradeModal(false);
  };

  const requestsColumns = [
    {
      title: t('common.merchantName'),
      dataIndex: 'merchantName',
      key: 'merchantName',
      render: (text: string, record: ITrade) => {
        return (
          <BaseTooltip title={`${record.firstName} ${record.lastName}`} color="black">
            <BaseRow>
              <span className="cut_text_small">{`${record.firstName} ${record.lastName}`}</span>
            </BaseRow>
          </BaseTooltip>
        );
      },
    },
    {
      title: t('common.currency'),
      dataIndex: 'currencyLongTitle',
      key: 'currencyLongTitle',
      render: (text: string, record: ITrade) => {
        return (
          <BaseRow>
            <span className="cut_text">{record.currencyLongTitle}</span>
          </BaseRow>
        );
      },
    },
    {
      title: t('common.cryptoCoin'),
      dataIndex: 'amount',
      key: 'amount',
      render: (text: string, record: ITrade) => {
        return <BaseRow>{record.amount}</BaseRow>;
      },
    },
    {
      title: t('common.usd'),
      dataIndex: 'usd',
      key: 'usd',
      render: (text: string, record: ITrade) => {
        const currency = currenciesRate[record?.currencyTitle]?.currentRate;
        const usd = `$${(currency * Number(record?.amount)).toFixed(2)}`;
        return <BaseRow>{usd}</BaseRow>;
      },
    },
    {
      title: t('common.userRateInAmd'),
      dataIndex: 'priceRate',
      key: 'priceRate',
      render: (text: string, record: ITrade) => {
        return (
          <BaseRow>
            <span>{`${record.priceRate}`}</span>
            <img src={armenianCurrencyIcon} alt="Armenian Valuta" />
          </BaseRow>
        );
      },
    },
    {
      title: t('common.banks'),
      dataIndex: 'banks',
      key: 'banks',
      render: (text: string, record: ITrade) => {
        const banks = record.banks.join(', ');
        return (
          <BaseRow>
            <BaseTooltip title={banks}>
              <div className="banks_section">{banks}</div>
            </BaseTooltip>
          </BaseRow>
        );
      },
    },
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      width: '15%',
      render: (text: string, record: ITrade) => {
        return (
          <BaseSpace>
            <BaseButton
              type="default"
              danger
              onClick={(event) => {
                event.stopPropagation();
                handleOpenCloseTradeModal(record.id);
              }}
            >
              {t('tables.close')}
            </BaseButton>
          </BaseSpace>
        );
      },
    },
  ];

  const commonTabs = useMemo(
    () => [
      {
        key: TRADES_TABES.active,
        label: `${t('tabs.active')}`,
      },
      {
        key: TRADES_TABES.inactive,
        label: `${t('tabs.inactive')}`,
      },
    ],
    [t],
  );

  const handleTabClick = (activeKey: string, e: React.KeyboardEvent | React.MouseEvent) => {
    setActiveTab(activeKey);
    query.set(TABLES.page, `${TABLES.first_page}`);
    navigate(`?${query.toString()}`);
  };

  useEffect(() => {
    if (selectedPage && Number(selectedPage) !== page.current) {
      setPage((state) => ({
        ...state,
        current: Number(selectedPage),
      }));
    }
  }, [selectedPage]);

  useEffect(() => {
    setPage((state) => ({ ...state, total: Number(total) }));
  }, [total]);

  useEffect(() => {
    if (isMounted) {
      const params = {
        page: `${page.current}`,
        per_page: `${page.pageSize}`,
        search: search ?? '',
        filters: {
          status: activeTab,
          state: TRADE_STATES.open,
        },
      };
      dispatch(getTrades({ params }));
    }
  }, [page, search, activeTab]);

  return (
    <BaseForm form={form} component={false}>
      <BaseCol className="fitWidth">
        <BaseTabs defaultActiveKey="1" items={commonTabs} onTabClick={handleTabClick}></BaseTabs>
      </BaseCol>
      <BaseTable
        columns={requestsColumns}
        dataSource={trades}
        pagination={page}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        onRow={(record) => {
          return {
            onClick: () => handleRowClick(record.id),
          };
        }}
      />
      <BaseModal
        title={t('modals.userActivation')}
        open={openCloseTradeModal}
        onOk={handleCloseTrade}
        onCancel={() => setOpenCloseTradeModal(false)}
      >
        <p>{t('modals.closeTradeMessage')}</p>
      </BaseModal>
    </BaseForm>
  );
};
