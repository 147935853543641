import React from 'react';
import { DashboardOutlined, HomeOutlined, TrademarkOutlined, UserOutlined } from '@ant-design/icons';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'common.dashboard',
    key: 'dashboard',
    // TODO use path variable
    url: '/',
    icon: <DashboardOutlined />,
  },
  {
    title: 'common.merchants',
    key: 'Merchants',
    url: '/merchants',
    icon: <UserOutlined />,
  },
  {
    title: 'common.clients',
    key: 'Clients',
    url: '/clients',
    icon: <UserOutlined />,
  },
  {
    title: 'common.trades',
    key: 'Trades',
    icon: <TrademarkOutlined />,
    children: [
      {
        title: 'common.ongoingTrades',
        key: 'ongoingTrades',
        url: '/trades/ongoing-trades',
      },
      {
        title: 'common.tradesHistory',
        key: 'tradesHistory',
        url: '/trades/trades-history',
      },
    ],
  },
  {
    title: 'common.content',
    key: 'Content',
    icon: <HomeOutlined />,
    children: [
      {
        title: 'common.mainSection',
        key: 'mainSection',
        url: '/content/main-section',
      },
      {
        title: 'common.forMerchants',
        key: 'forMerchants',
        url: '/content/for-merchants',
      },
      {
        title: 'common.faqPage',
        key: 'faqPage',
        url: '/content/faq',
      },
      {
        title: 'common.aboutUs',
        key: 'aboutUs',
        url: '/content/about-us',
      },
      {
        title: 'common.privacyPolicy',
        key: 'privacyPolicy',
        url: '/content/privacy-policy',
      },
      {
        title: 'common.termsOfUse',
        key: 'termsOfUse',
        url: '/content/terms-of-use',
      },
      {
        title: 'common.socialLinks',
        key: 'socialLinks',
        url: '/content/social-links',
      },
      {
        title: 'common.contactInformation',
        key: 'contactInformation',
        url: '/content/contact-information',
      },
    ],
  },
];
