import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { FormProps } from 'antd';
import { IBody } from '@app/api/content.api';
import { changePassword } from '@app/store/actions/auth';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';

import './style.css';
import { InputPassword } from '@app/components/common/inputs/InputPassword/InputPassword.styles';

const ChangePassword: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [form] = BaseForm.useForm();

  const onFinish: FormProps<IBody>['onFinish'] = (values) => {
    dispatch(changePassword({ data: values }));
  };

  return (
    <>
      <PageTitle>{t('profile.changePassword')}</PageTitle>
      <div className="change_password_field">
        <div className="change_password_title">{t('profile.changePassword')}</div>
        <BaseCol>
          <BaseForm form={form} layout="vertical" name="userForm" onFinish={onFinish}>
            <BaseForm.Item
              name={`oldPassword`}
              label={t('common.oldPassword')}
              rules={[
                {
                  required: true,
                  message: t('common.requiredField'),
                  whitespace: false,
                  validateTrigger: 'onSubmit',
                },
                {
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.resolve();
                    }

                    return Promise.resolve();
                  },
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <InputPassword />
            </BaseForm.Item>
            <BaseForm.Item
              name={`newPassword`}
              label={t('common.newPassword')}
              rules={[
                {
                  required: true,
                  message: t('common.requiredField'),
                  whitespace: false,
                  validateTrigger: 'onSubmit',
                },
                {
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.resolve();
                    }

                    const hasLowercase = /[a-z]/.test(value);
                    const hasUppercase = /[A-Z]/.test(value);
                    const hasNumeric = /[0-9]/.test(value);

                    if (!hasLowercase || !hasUppercase || !hasNumeric) {
                      return Promise.reject(new Error(t('newPassword.patterError')));
                    }

                    return Promise.resolve();
                  },
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <InputPassword />
            </BaseForm.Item>
            <BaseForm.Item
              name={`passwordConfirm`}
              dependencies={['newPassword']}
              label={t('common.passwordConfirm')}
              rules={[
                {
                  required: true,
                  message: t('common.requiredField'),
                  whitespace: false,
                  validateTrigger: 'onSubmit',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('common.confirmPasswordError')));
                  },
                }),
              ]}
            >
              <InputPassword />
            </BaseForm.Item>
            <BaseButton type="primary" htmlType="submit">
              Save
            </BaseButton>
          </BaseForm>
        </BaseCol>
      </div>
    </>
  );
};

export default ChangePassword;
