import { httpApi } from '@app/api/http.api';
import { UserModel } from '@app/domain/UserModel';

export interface AuthData {
  email: string;
  password: string;
}

export interface SignUpRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface SecurityCodePayload {
  code: string;
}

export interface NewPasswordData {
  newPassword: string;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface IStrings {
  [key: string]: string;
}

export interface IChangePassword {
  data: IStrings;
}

export interface LoginResponse {
  token: string;
  user: UserModel;
}

export interface IError {
  response: {
    status: number;
    data: { message: string; status: number };
  };
}

export const login = async (body: LoginRequest) => await httpApi.post('/v1/admin/auth/signin', body);
export const getUserApi = async () => await httpApi.get('/v1/users/user');
export const changePasswordApi = async (body: IStrings) => await httpApi.post('/v1/auth/change-password', body);
