import {
  IFaqIdUpdate,
  IFaqParamsQuery,
  IMainSectionData,
  ISocialFormTypes,
  ISocialUpdate,
  IUpdateData,
  IUpdateId,
  createAboutUsApi,
  createContactUsApi,
  createFaqApi,
  createForMerchantsApi,
  createMainSectionApi,
  createPrivacyPolicyApi,
  createSocialIconsApi,
  createTermsOfUseApi,
  deleteFaqApi,
  deleteSocialIconApi,
  getAboutUsApi,
  getContactUsApi,
  getFaqApi,
  getFaqByIdApi,
  getForMerchantsApi,
  getMainSectionApi,
  getPrivacyPolicyApi,
  getSocialIconByIdApi,
  getSocialIconsApi,
  getTermsOfUseApi,
  updateAboutUsApi,
  updateContactUsApi,
  updateFaqApi,
  updateForMerchantsApi,
  updateMainSectionApi,
  updatePrivacyPolicyApi,
  updateSocialIconsApi,
  updateTermsOfUseApi,
} from '@app/api/content.api';
import { NOTIFY_MESSAGE } from '@app/constants/content';
import { notificationController } from '@app/controllers/notificationController';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getMainSectionData = createAsyncThunk('getMainSectionData', async (_, { rejectWithValue }) => {
  try {
    const { data } = await getMainSectionApi();
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateMainSection = createAsyncThunk(
  'updateMainSection',
  async ({ data }: IMainSectionData, { rejectWithValue, dispatch }) => {
    try {
      await updateMainSectionApi(data);
      notificationController.success({ message: NOTIFY_MESSAGE.success_update });
      dispatch(getMainSectionData());
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createMainSection = createAsyncThunk(
  'createMainSection',
  async ({ data }: IMainSectionData, { rejectWithValue, dispatch }) => {
    try {
      await createMainSectionApi(data);
      notificationController.success({ message: NOTIFY_MESSAGE.success_update });
      dispatch(getMainSectionData());
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getForMerchantsData = createAsyncThunk('getForMerchantsData', async (_, { rejectWithValue }) => {
  try {
    const { data } = await getForMerchantsApi();
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateForMerchants = createAsyncThunk(
  'updateForMerchant',
  async ({ data }: IMainSectionData, { rejectWithValue, dispatch }) => {
    try {
      await updateForMerchantsApi(data);
      notificationController.success({ message: NOTIFY_MESSAGE.success_update });
      dispatch(getForMerchantsData());
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createForMerchants = createAsyncThunk(
  'createForMerchants',
  async ({ data }: IMainSectionData, { rejectWithValue, dispatch }) => {
    try {
      await createForMerchantsApi(data);
      notificationController.success({ message: NOTIFY_MESSAGE.success_update });
      dispatch(getForMerchantsData());
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getAboutUs = createAsyncThunk('getAboutUs', async (_, { rejectWithValue }) => {
  try {
    const { data } = await getAboutUsApi();
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateAboutUs = createAsyncThunk(
  'updateAboutUs',
  async ({ data }: IMainSectionData, { rejectWithValue, dispatch }) => {
    try {
      await updateAboutUsApi(data);
      notificationController.success({ message: NOTIFY_MESSAGE.success_update });
      dispatch(getAboutUs());
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createAboutUs = createAsyncThunk(
  'createAboutUs',
  async ({ data }: IMainSectionData, { rejectWithValue, dispatch }) => {
    try {
      await createAboutUsApi(data);
      notificationController.success({ message: NOTIFY_MESSAGE.success_update });
      dispatch(getAboutUs());
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getPrivacyPolicy = createAsyncThunk('getPrivacyPolicy', async (_, { rejectWithValue }) => {
  try {
    const { data } = await getPrivacyPolicyApi();
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updatePrivacyPolicy = createAsyncThunk(
  'updatePrivacyPolicy',
  async ({ data }: IMainSectionData, { rejectWithValue, dispatch }) => {
    try {
      await updatePrivacyPolicyApi(data);
      notificationController.success({ message: NOTIFY_MESSAGE.success_update });
      dispatch(getPrivacyPolicy());
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createPrivacyPolicy = createAsyncThunk(
  'createPrivacyPolicy',
  async ({ data }: IMainSectionData, { rejectWithValue, dispatch }) => {
    try {
      await createPrivacyPolicyApi(data);
      notificationController.success({ message: NOTIFY_MESSAGE.success_update });
      dispatch(getPrivacyPolicy());
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getTermsOfUse = createAsyncThunk('getTermsOfUse', async (_, { rejectWithValue }) => {
  try {
    const { data } = await getTermsOfUseApi();
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateTermsOfUse = createAsyncThunk(
  'updateTermsOfUse',
  async ({ data }: IMainSectionData, { rejectWithValue, dispatch }) => {
    try {
      await updateTermsOfUseApi(data);
      notificationController.success({ message: NOTIFY_MESSAGE.success_update });
      dispatch(getTermsOfUse());
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createTermsOfUse = createAsyncThunk(
  'createTermsOfUse',
  async ({ data }: IMainSectionData, { rejectWithValue, dispatch }) => {
    try {
      await createTermsOfUseApi(data);
      notificationController.success({ message: NOTIFY_MESSAGE.success_update });
      dispatch(getTermsOfUse());
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getContactUs = createAsyncThunk('getContactUs', async (_, { rejectWithValue }) => {
  try {
    const { data } = await getContactUsApi();
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateContactUs = createAsyncThunk(
  'updateContactUs',
  async ({ data }: IMainSectionData, { rejectWithValue, dispatch }) => {
    try {
      await updateContactUsApi(data);
      notificationController.success({ message: NOTIFY_MESSAGE.success_update });
      dispatch(getContactUs());
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createContactUs = createAsyncThunk(
  'createContactUs',
  async ({ data }: IMainSectionData, { rejectWithValue, dispatch }) => {
    try {
      await createContactUsApi(data);
      notificationController.success({ message: NOTIFY_MESSAGE.success_update });
      dispatch(getContactUs());
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getFaqData = createAsyncThunk('getFaqData', async ({ params }: IFaqParamsQuery, { rejectWithValue }) => {
  try {
    const { data } = await getFaqApi(params);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getFaqDataById = createAsyncThunk('getFaqDataById', async ({ id }: IUpdateId, { rejectWithValue }) => {
  try {
    const params = { language: 'all' };
    const { data } = await getFaqByIdApi(params, id);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateFaq = createAsyncThunk(
  'updateFaq',
  async ({ data, id }: IUpdateData, { rejectWithValue, dispatch }) => {
    try {
      await updateFaqApi(data, id);
      notificationController.success({ message: NOTIFY_MESSAGE.success_update });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createFaq = createAsyncThunk(
  'createFaq',
  async ({ data }: IMainSectionData, { rejectWithValue, dispatch }) => {
    try {
      await createFaqApi(data);
      notificationController.success({ message: NOTIFY_MESSAGE.success_create });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteFaq = createAsyncThunk(
  'deleteFaq',
  async ({ id, params }: IFaqIdUpdate, { dispatch, rejectWithValue }) => {
    try {
      await deleteFaqApi(id);
      notificationController.success({ message: NOTIFY_MESSAGE.success_delete });
      dispatch(getFaqData({ params }));
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getSocialIcons = createAsyncThunk('getSocialIcons', async (_, { rejectWithValue }) => {
  try {
    const { data } = await getSocialIconsApi();
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSocialIconById = createAsyncThunk(
  'getSocialIconById',
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const { data } = await getSocialIconByIdApi(id);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateSocialIcons = createAsyncThunk(
  'updateSocialIcons',
  async ({ data, id, handleNavigate }: ISocialUpdate, { rejectWithValue }) => {
    try {
      await updateSocialIconsApi(data, id);
      notificationController.success({ message: NOTIFY_MESSAGE.success_update });
      handleNavigate();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createSocialIcons = createAsyncThunk(
  'createSocialIcons',
  async ({ data, handleNavigate }: ISocialFormTypes, { rejectWithValue }) => {
    try {
      await createSocialIconsApi(data);
      notificationController.success({ message: NOTIFY_MESSAGE.success_create });
      handleNavigate();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteSocialIcon = createAsyncThunk(
  'deleteSocialIcon',
  async ({ id }: { id: number }, { dispatch, rejectWithValue }) => {
    try {
      await deleteSocialIconApi(id);
      notificationController.success({ message: NOTIFY_MESSAGE.success_delete });
      dispatch(getSocialIcons());
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
