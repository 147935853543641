import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseFormItemProps } from '@app/components/common/forms/components/BaseFormItem/BaseFormItem';

interface EmailItemProps extends BaseFormItemProps {
  verified?: boolean;
  onClick?: () => void;
}

export const EmailItem: React.FC<EmailItemProps> = ({ required, onClick, verified, ...props }) => {
  const { t } = useTranslation();

  return (
    <BaseButtonsForm.Item name="email" label={t('common.email')}>
      <BaseInput disabled />
    </BaseButtonsForm.Item>
  );
};
