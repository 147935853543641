import { Priority } from '../constants/enums/priorities';
import { httpApi } from './http.api';

export interface Tag {
  value: string;
  priority: Priority;
}

export interface BasicTableRow {
  key: number;
  name: string;
  age: number;
  address: string;
  tags?: Tag[];
}

export interface UsersTableRow {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  nickname: string | null;
  role: string;
  isVerified: boolean;
  isEnabled2FA: boolean;
  telegramUserName: string;
  isBlocked: boolean;
  phoneNumber: string;
  created_at: string;
  status?: string;
  isVerifiedByAdmin: boolean;
}

export interface IInvitation {
  id: number;
  status: string;
  email: string;
  created_at: string;
}

export interface IUsersTable {
  data: UsersTableRow[];
  pagination: Pagination;
}

export interface Pagination {
  current?: number;
  pageSize?: number;
  total?: number;
}

export interface BasicTableData {
  data: BasicTableRow[];
  pagination: Pagination;
}

export interface TreeTableRow extends BasicTableRow {
  children?: TreeTableRow[];
}

export interface TreeTableData extends BasicTableData {
  data: TreeTableRow[];
}

export interface EditableTableData extends BasicTableData {
  data: BasicTableRow[];
}

export interface IColumns {
  title: string;
  dataIndex: string;
  key: string;
  render?: () => void;
}
export interface IColumnsData {
  columns: IColumns[];
}

export interface IWallet {
  id: number;
  userId: number;
  walletId: number;
  walletAddress: string;
  currency: string;
  amount: number;
  full_title: string;
  icon: string;
  quantity: number;
  title: string;
}
export interface IUser {
  id: number | null;
  email: string;
  role: string;
  firstName: string;
  lastName: string;
  nickname: string | null;
  telegramUserName?: string | null;
  isVerified: boolean;
  wallets: IWallet[];
  secret2FA: string | null;
  isEnabled2FA: boolean;
  isBlocked: boolean;
  passportImage: string[] | null;
  phoneNumber: string | null;
  balance: number | null;
}

export interface IBalance {
  amount: number | null;
  currency: string;
  date: string;
  direction: string;
  type: string;
}

export interface IUsersSlice {
  users: UsersTableRow[];
  merchants: UsersTableRow[];
  loading: boolean;
  total: number;
  merchantsTotal: number;
  merchantsLoading: boolean;
  invitations: IInvitation[];
  invitationLoading: boolean;
  invitationTotal: number;
  userDetails: IUser;
  userDetailsLoading: boolean;
  balanceHistory: IBalance[];
  balanceHistoryLoading: boolean;
  balanceHistoryTotal: number;
}
export interface IUsersParams {
  page?: number;
  per_page?: number;
  search?: string;
  filters?: { role: string };
}

export interface IUsersParamsQuery {
  params: IUsersParams;
}

export interface IBody {
  [key: string]: string;
}

export interface IInviteMerchant {
  data: IBody;
  handleAction?: () => void;
}

export interface IVerifyUserData {
  isVerifiedByAdmin: boolean;
}

export interface IVerifyUser {
  id: number;
  data: IVerifyUserData;
  params: IUsersParams;
}

export const getUsersApi = async (params: IUsersParams) => await httpApi.get('/v1/admin/users', { params });
export const getMerchantsApi = async (params: IUsersParams) =>
  await httpApi.get('/v1/admin/merchants/requests', { params });
export const inviteMerchantApi = async (body: IBody) => await httpApi.post('/v1/admin/merchants/invite', body);
export const acceptRejectMerchantApi = async (body: IBody) =>
  await httpApi.post(`/v1/admin/merchants/request/action`, body);
export const getInvitationsApi = async (params: IUsersParams) =>
  await httpApi.get('/v1/admin/merchants/invitations', { params });
export const resetUserTwoFactorApi = async (id: number) => await httpApi.put(`/v1/admin/users/${id}/disable2FA`);
export const verifyUserByAdminApi = async (id: number, body: IVerifyUserData) =>
  await httpApi.put(`/v1/admin/users/${id}/verify-status`, body);

export const blockUserApi = async (id: number) => await httpApi.post(`/v1/admin/users/block/${id}`);
export const getUserByIdApi = async (id: number) => await httpApi.get(`/v1/admin/users/${id}`);
export const getUserBalanceHistoryApi = async (id: number) => await httpApi.get(`/v1/admin/users/transactions/${id}`);
export const getRequestedUserApi = async (id: number) => await httpApi.get(`/v1/admin/merchants/requests/${id}`);

export const getBasicTableData = (pagination: Pagination): Promise<BasicTableData> => {
  return new Promise((res) => {
    setTimeout(() => {
      res({
        data: [
          {
            key: 1,
            name: 'John Brown',
            age: 32,
            address: 'New York No. 1 Lake Park',
            tags: [
              { value: 'Architect', priority: Priority.LOW },
              { value: 'Engineer', priority: Priority.MEDIUM },
            ],
          },
          {
            key: 2,
            name: 'Jim Green',
            age: 42,
            address: 'London No. 1 Lake Park',
            tags: [{ value: 'Doctor', priority: Priority.HIGH }],
          },
          {
            key: 3,
            name: 'Joe Black',
            age: 32,
            address: 'Sidney No. 1 Lake Park',
            tags: [
              { value: 'Professor', priority: Priority.INFO },
              { value: 'Architect', priority: Priority.LOW },
            ],
          },
          {
            key: 4,
            name: 'Pavel Green',
            age: 30,
            address: 'New York No. 1 Lake Park',
            tags: [
              { value: 'Engineer', priority: Priority.MEDIUM },
              { value: 'Architect', priority: Priority.LOW },
            ],
          },
          {
            key: 5,
            name: 'Alex Brown',
            age: 26,
            address: 'Minsk',
            tags: [{ value: 'Engineer', priority: Priority.MEDIUM }],
          },
          {
            key: 6,
            name: 'Josh Black',
            age: 21,
            address: 'New York No. 1 Lake Park',
            tags: [
              { value: 'Teacher', priority: Priority.INFO },
              { value: 'Architect', priority: Priority.LOW },
            ],
          },
          {
            key: 7,
            name: 'Cris Green',
            age: 22,
            address: 'Sidney No. 1 Lake Park',
            tags: [{ value: 'Architect', priority: Priority.LOW }],
          },
          {
            key: 8,
            name: 'Jaime Black',
            age: 23,
            address: 'New York No. 1 Lake Park',
            tags: [{ value: 'Engineer', priority: Priority.MEDIUM }],
          },
          {
            key: 9,
            name: 'Alina Brown',
            age: 19,
            address: 'Minsk',
            tags: [
              { value: 'Professor', priority: Priority.LOW },
              { value: 'Teacher', priority: Priority.INFO },
            ],
          },
          {
            key: 10,
            name: 'Cris Brown',
            age: 25,
            address: 'London',
            tags: [
              { value: 'Engineer', priority: Priority.MEDIUM },
              { value: 'Teacher', priority: Priority.INFO },
            ],
          },
          {
            key: 11,
            name: 'Alina Fens',
            age: 19,
            address: 'Minsk',
            tags: [
              { value: 'Professor', priority: Priority.LOW },
              { value: 'Teacher', priority: Priority.INFO },
            ],
          },
          {
            key: 12,
            name: 'Alex Snak',
            age: 28,
            address: 'Brest',
            tags: [
              { value: 'Professor', priority: Priority.LOW },
              { value: 'Doctor', priority: Priority.HIGH },
            ],
          },
          {
            key: 13,
            name: 'Pavel Dubrouski',
            age: 26,
            address: 'Minsk',
            tags: [
              { value: 'Professor', priority: Priority.LOW },
              { value: 'Doctor', priority: Priority.HIGH },
              { value: 'Teacher', priority: Priority.INFO },
              { value: 'Engineer', priority: Priority.MEDIUM },
            ],
          },
          {
            key: 14,
            name: 'Jack Donald',
            age: 24,
            address: 'New York',
            tags: [{ value: 'Professor', priority: Priority.LOW }],
          },
          {
            key: 15,
            name: 'Nik Nest',
            age: 34,
            address: 'London',
            tags: [
              { value: 'Doctor', priority: Priority.HIGH },
              { value: 'Engineer', priority: Priority.MEDIUM },
            ],
          },
          {
            key: 16,
            name: 'Zak Nikls',
            age: 32,
            address: 'Minsk',
            tags: [
              { value: 'Doctor', priority: Priority.HIGH },
              { value: 'Teacher', priority: Priority.INFO },
            ],
          },
          {
            key: 17,
            name: 'Petr Dan',
            age: 29,
            address: 'Gomel',
            tags: [
              { value: 'Engineer', priority: Priority.MEDIUM },
              { value: 'Teacher', priority: Priority.INFO },
            ],
          },
          {
            key: 18,
            name: 'Alexa Pirs',
            age: 19,
            address: 'Moscow',
            tags: [
              { value: 'Professor', priority: Priority.LOW },
              { value: 'Doctor', priority: Priority.HIGH },
            ],
          },
          {
            key: 19,
            name: 'Mark Brown',
            age: 25,
            address: 'London',
            tags: [
              { value: 'Teacher', priority: Priority.INFO },
              { value: 'Doctor', priority: Priority.HIGH },
            ],
          },
          {
            key: 20,
            name: 'Alex Brons',
            age: 45,
            address: 'Bronx',
            tags: [{ value: 'Professor', priority: Priority.LOW }],
          },
        ],
        pagination: { ...pagination, total: 20 },
      });
    }, 1000);
  });
};

export const getEditableTableData = (pagination: Pagination): Promise<EditableTableData> => {
  return new Promise((res) => {
    setTimeout(() => {
      res({
        data: [
          {
            key: 1,
            name: `Edward`,
            age: 32,
            address: `London Park no.1`,
          },
          {
            key: 2,
            name: `Alex`,
            age: 45,
            address: `London Park no.2`,
          },
          {
            key: 3,
            name: `Niko`,
            age: 21,
            address: `London Park no.3`,
          },
          {
            key: 4,
            name: `Josh`,
            age: 18,
            address: `London Park no.4`,
          },
          {
            key: 5,
            name: `Jo`,
            age: 15,
            address: `Minsk Park no.1`,
          },
          {
            key: 6,
            name: `Jaimi`,
            age: 18,
            address: `London Park no.2`,
          },
          {
            key: 7,
            name: `Alexa`,
            age: 24,
            address: `London Park no.6`,
          },
          {
            key: 8,
            name: `Donald`,
            age: 27,
            address: `London Park no.7`,
          },
          {
            key: 9,
            name: `Pavel`,
            age: 17,
            address: `London Park no.9`,
          },
          {
            key: 10,
            name: `Nick`,
            age: 18,
            address: `London Park no.1`,
          },
          {
            key: 11,
            name: `Dasha`,
            age: 25,
            address: `London Park no.2`,
          },
          {
            key: 12,
            name: `Alex`,
            age: 27,
            address: `London Park no.3`,
          },
          {
            key: 13,
            name: `Vic`,
            age: 29,
            address: `London Park no.2`,
          },
          {
            key: 14,
            name: `Natalia`,
            age: 25,
            address: `London Park no.4`,
          },
          {
            key: 15,
            name: `Zack`,
            age: 27,
            address: `London Park no.1`,
          },
          {
            key: 16,
            name: `Jack`,
            age: 31,
            address: `London Park no.4`,
          },
        ],
        pagination: { ...pagination, total: 16 },
      });
    }, 1000);
  });
};
