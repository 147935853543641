import React, { useEffect } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';

import './style.css';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { getMerchantUsers, getUsers } from '@app/store/actions/users';
import { TABLES, TRADE_STATUSES } from '@app/constants/tables';
import { getTrades } from '@app/store/actions/trades';
import { Loading } from '@app/components/common/Loading/Loading';
import { useTranslation } from 'react-i18next';

const MedicalDashboardPage: React.FC = () => {
  const { t } = useTranslation();

  const merchantsTotal = useAppSelector((state) => state.users.merchantsTotal);
  const tradesTotal = useAppSelector((state) => state.trades.tradeTotal);
  const usersTotal = useAppSelector((state) => state.users.total);

  const merchantsLoading = useAppSelector((state) => state.users.merchantsLoading);
  const usersLoading = useAppSelector((state) => state.users.loading);
  const tradesLoading = useAppSelector((state) => state.trades.tradeLoading);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const merchantsParams = {
      filters: { role: TABLES.merchant_role },
    };
    dispatch(getMerchantUsers({ params: merchantsParams }));
    const userParams = {
      filters: { role: TABLES.user_role },
    };
    dispatch(getUsers({ params: userParams }));
    const tradeParams = {
      filters: {
        statuses: [TRADE_STATUSES.completed],
      },
    };
    dispatch(getTrades({ params: tradeParams }));
  }, []);

  return (
    <>
      <PageTitle>Kobbex Admin Dashboard</PageTitle>
      <BaseRow>
        {merchantsLoading || usersLoading || tradesLoading ? (
          <Loading />
        ) : (
          <div className="dashboard">
            <div className="dashboard_element merchants_field">
              <p className="dashboard_element_title">{t('common.merchantsCount')}</p>
              <p className="dashboard_element_title">{`Count - ${merchantsTotal}`}</p>
            </div>
            <div className="dashboard_element users_field">
              <p className="dashboard_element_title">{t('common.usersCount')}</p>
              <p className="dashboard_element_title">{`Count - ${usersTotal}`}</p>
            </div>
            <div className="dashboard_element trades_field">
              <p className="dashboard_element_title">{t('common.tradesCount')}</p>
              <p className="dashboard_element_title">{`Count - ${tradesTotal}`}</p>
            </div>
          </div>
        )}
      </BaseRow>
    </>
  );
};

export default MedicalDashboardPage;
