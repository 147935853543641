import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { FaqTable } from '@app/components/tables/Tables/FaqTable';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setFaqEditData } from '@app/store/slices/contentSlice';
import { FAQ_DATA } from '@app/constants/content';

const Faq: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleCreate = () => {
    dispatch(setFaqEditData(FAQ_DATA));
    navigate('create');
  };

  return (
    <>
      <PageTitle>{t('common.merchants')}</PageTitle>
      <BaseCol flex={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <BaseButton type="primary" onClick={handleCreate}>
          {t('buttons.create')}
        </BaseButton>
      </BaseCol>

      <FaqTable />
    </>
  );
};

export default Faq;
