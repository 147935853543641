export { doLogin } from './auth';
export {
  getMainSectionData,
  updateMainSection,
  createMainSection,
  getForMerchantsData,
  updateForMerchants,
  createForMerchants,
  getAboutUs,
  updateAboutUs,
  createAboutUs,
  getPrivacyPolicy,
  updatePrivacyPolicy,
  createPrivacyPolicy,
  getTermsOfUse,
  updateTermsOfUse,
  createTermsOfUse,
  getContactUs,
  updateContactUs,
  createContactUs,
  getFaqData,
  getFaqDataById,
  createFaq,
  updateFaq,
  deleteFaq,
  getSocialIcons,
  createSocialIcons,
  updateSocialIcons,
  getSocialIconById,
} from './content';
