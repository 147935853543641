import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { HeaderActionWrapper } from '@app/components/header/Header.styles';
import { CategoryComponents } from '@app/components/header/components/HeaderSearch/HeaderSearch';
import { InputSearch, Btn } from '../HeaderSearch/HeaderSearch.styles';
import { useTranslation } from 'react-i18next';
import { BasePopover } from '@app/components/common/BasePopover/BasePopover';
import { useLocation, useNavigate } from 'react-router-dom';
import { TABLES } from '@app/constants/tables';
import { ClearIcon } from 'components/common/icons/Clear';

interface SearchOverlayProps {
  query: string;
  setQuery: (query: string) => void;
  data: CategoryComponents[] | null;
  isOverlayOpen: boolean;
  setOverlayOpen: (state: boolean) => void;
}

export const SearchDropdown: React.FC<SearchOverlayProps> = ({ query, setQuery }) => {
  const { t } = useTranslation();
  const queryParam = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const timeoutRef = useRef<NodeJS.Timeout>();

  const handleSearchQuery = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      queryParam.set(TABLES.search, event.target.value);
      navigate(`?${queryParam.toString()}`);
    }, 300);
  };

  const handleClearSearch = () => {
    setQuery('');
    queryParam.set(TABLES.search, '');
    navigate(`?${queryParam.toString()}`);
  };

  useEffect(() => {
    if (queryParam.get(TABLES.search)) setQuery(queryParam.get(TABLES.search) as string);
  }, []);

  useEffect(() => {
    setQuery('');
  }, [pathname]);

  return (
    <>
      <BasePopover overlayClassName="search-overlay">
        <HeaderActionWrapper>
          <InputSearch
            width="100%"
            value={query}
            placeholder={t('header.search')}
            onChange={handleSearchQuery}
            filter={
              <Btn size="small" type="text" aria-label="Filter" icon={<ClearIcon />} onClick={handleClearSearch} />
            }
            enterButton={null}
            addonAfter={null}
          />
        </HeaderActionWrapper>
      </BasePopover>
    </>
  );
};
