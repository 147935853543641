import { IContactUs } from '@app/api/content.api';
import { IBody } from '@app/api/table.api';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { createContactUs, getContactUs, updateContactUs } from '@app/store/actions';
import { FormProps } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const ContactUs = () => {
  const data = useAppSelector((state) => state.content.contactUs);

  const { t } = useTranslation();
  const [form] = BaseForm.useForm();
  const dispatch = useAppDispatch();

  const onFinish: FormProps<IBody>['onFinish'] = (values) => {
    if (data) {
      dispatch(updateContactUs({ data: values }));
    } else {
      dispatch(createContactUs({ data: values }));
    }
  };

  useEffect(() => {
    dispatch(getContactUs());
  }, []);

  useEffect(() => {
    if (data) {
      for (const key in data) {
        const value = key as keyof IContactUs;
        form.setFieldValue(key, data[value]);
      }
    }
  }, [data]);

  return (
    <>
      <BaseCol>
        <BaseForm form={form} layout="vertical" name="userForm" onFinish={onFinish}>
          <BaseForm.Item
            name={`phone`}
            label={t('common.phone')}
            rules={[
              {
                required: true,
                message: t('common.requiredField'),
                whitespace: false,
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <BaseInput placeholder={t('common.phone')} />
          </BaseForm.Item>
          <BaseForm.Item
            name={`address`}
            label={t('common.address')}
            rules={[
              {
                required: true,
                message: t('common.requiredField'),
                whitespace: false,
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <BaseInput placeholder={t('common.address')} />
          </BaseForm.Item>
          <BaseForm.Item
            name={`email`}
            label={t('common.email')}
            rules={[
              {
                required: true,
                message: t('common.requiredField'),
                type: 'email',
                whitespace: false,
                validateTrigger: 'onSubmit',
              },
              {
                type: 'email',
                message: 'Please enter a valid email address!',
              },
            ]}
          >
            <BaseInput placeholder={t('common.email')} />
          </BaseForm.Item>
          <BaseButton type="primary" htmlType="submit">
            Save
          </BaseButton>
        </BaseForm>
      </BaseCol>
    </>
  );
};

export default ContactUs;
