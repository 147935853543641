import { FC, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';

import rightIcon from './images/chevron-right.svg';
import leftIcon from './images/chevron-left.svg';

const imageUrl = process.env.REACT_APP_IMAGE_URL;

interface IChatImagesModal {
  onClose: () => void;
  images: string[] | File[];
  selectedImageIndex: number;
}

export const ChatImagesModal: FC<IChatImagesModal> = ({ images, selectedImageIndex }) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(selectedImageIndex);

  const goNext = () => {
    if (images.length - 1 === selectedIndex) {
      setSelectedIndex(0);
    } else {
      setSelectedIndex(selectedIndex + 1);
    }
  };

  const goPrev = () => {
    if (selectedIndex === 0) {
      setSelectedIndex(images.length - 1);
    } else {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  return (
    <div className="modal passport_modal">
      <div className="logout_header"></div>
      <div className="images_field">
        <div>
          {images.map(
            (image, index) =>
              selectedIndex === index && (
                <div key={uuidv4()}>
                  <img
                    src={typeof image === 'string' ? `${imageUrl}${image}` : URL.createObjectURL(image)}
                    crossOrigin="anonymous"
                    alt="Passport"
                    className="swiper_image_element"
                  />
                </div>
              ),
          )}
        </div>
      </div>
      <div className="arrow_container">
        <img src={leftIcon} alt="Left" className="chevron_icon" onClick={goPrev} />
        <img src={rightIcon} alt="Right" className="chevron_icon" onClick={goNext} />
      </div>
    </div>
  );
};
