import React from 'react';
import { useTranslation } from 'react-i18next';

import { OngoingTradesTreeTable } from '../TreeTable/OngoingTradesTreeTable';

import * as S from './Tables.styles';

export const OngoingTradesTable: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <S.TablesWrapper>
        <S.Card id="tree-table" title={t('common.ongoingTrades')} padding="1.25rem 1.25rem 0">
          <OngoingTradesTreeTable />
        </S.Card>
      </S.TablesWrapper>
    </>
  );
};
