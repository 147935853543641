import { IError } from '@app/api/auth.api';
import {
  IChangeStatusRequest,
  ICloseTrade,
  IRequestParams,
  ITradeParams,
  changeRequestStatusApi,
  closeTradeApi,
  getChatMessagesApi,
  getTradeRequestsApi,
  getTradesApi,
} from '@app/api/trades.api';
import { notificationController } from '@app/controllers/notificationController';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getTrades = createAsyncThunk('getTrades', async ({ params }: ITradeParams, { rejectWithValue }) => {
  try {
    const { data } = await getTradesApi(params);
    return data?.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getTradeRequests = createAsyncThunk(
  'getTradeRequests',
  async ({ id, params }: IRequestParams, { rejectWithValue }) => {
    try {
      const { data } = await getTradeRequestsApi(id, params);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getChatMessages = createAsyncThunk(
  'getChatMessages',
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const { data } = await getChatMessagesApi(id);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const closeTrade = createAsyncThunk(
  'closeTradeApi',
  async ({ id, params }: ICloseTrade, { rejectWithValue, dispatch }) => {
    try {
      const res = await closeTradeApi(id);
      notificationController.success({ message: res.data.message });
      dispatch(getTrades({ params }));
    } catch (error) {
      const typedError = error as IError;
      notificationController.error({ message: typedError.response.data.message });
      return rejectWithValue(error);
    }
  },
);

export const changeRequestStatus = createAsyncThunk(
  'changeRequestStatus',
  async ({ id, data, params, trade_id }: IChangeStatusRequest, { rejectWithValue, dispatch }) => {
    try {
      const res = await changeRequestStatusApi(id, data);
      notificationController.success({ message: res.data.message });
      dispatch(getTradeRequests({ params, id: trade_id }));
    } catch (error) {
      const typedError = error as IError;
      notificationController.error({ message: typedError.response.data.message });
      return rejectWithValue(error);
    }
  },
);
