import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './Tables.styles';
import { SocialIconsTreeTable } from '../TreeTable/SocialIconsTreeTable';

export const SocialIconsTable: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <S.TablesWrapper>
        <S.Card id="tree-table" title={t('common.socialLinks')} padding="1.25rem 1.25rem 0">
          <SocialIconsTreeTable />
        </S.Card>
      </S.TablesWrapper>
    </>
  );
};
