import { Dispatch, FC, MouseEvent, SetStateAction, memo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import './style.css';

import closeIcon from './images/close.svg';

interface IImages {
  setValue: Dispatch<SetStateAction<File[]>>;
  value: File[];
}
export const ImagesGrid: FC<IImages> = memo(({ setValue, value }) => {
  const handleRemove = (e: MouseEvent<HTMLElement>, index: number) => {
    e.stopPropagation();

    const newElements = value.filter((elem: File, idx: number) => idx !== index);

    setValue([...newElements]);
  };

  return (
    <div className="images_grid">
      {value.map((element: File, index: number) => (
        <div className="grid_element" key={uuidv4()}>
          <div className="remove_icon">
            <img src={closeIcon} alt="Close" className="close_icon" onClick={(e) => handleRemove(e, index)} />
          </div>
          <div className="image_grid_field">
            <img src={URL.createObjectURL(element)} className="grid_image" />
          </div>
        </div>
      ))}
    </div>
  );
});
