import { Loading } from '@app/components/common/Loading/Loading';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { PersonalInfo } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/PersonalInfo';
import { TAB_VALUES } from '@app/constants/tables';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { getRequestedUser, getUserBalanceHistory, getUserById } from '@app/store/actions/users';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

export const UserDetails = () => {
  const loading = useAppSelector((state) => state.users.userDetailsLoading);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { id } = useParams();
  const { state } = useLocation();

  useEffect(() => {
    if (id) {
      if (state === TAB_VALUES.approved) {
        dispatch(getUserById({ id: Number(id) }));
        dispatch(getUserBalanceHistory({ id: Number(id) }));
      } else {
        dispatch(getRequestedUser({ id: Number(id) }));
      }
    }
  }, [id]);

  return (
    <>
      <PageTitle>{t('profile.nav.personalInfo.title')}</PageTitle>
      {loading ? <Loading /> : <PersonalInfo />}
    </>
  );
};
